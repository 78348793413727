.app-bar {
    height: 100%;
    -webkit-transition: min-width .1s, background-color .5s;
    transition: min-width .1s, background-color .5s;
    box-shadow: 0 3px 10px -3px rgba(0,0,0,.3);
    z-index: 1;
    padding-left: 1%;
}

.app-bar-expand-button {
    top: 50%;
    position: absolute;
    cursor: pointer;
}

.app-bar-large-expanded-style {
    min-width: 30%;
}

.app-bar-minimized-style {
    min-width: 5%;
}

.app-bar-on-call-style {
    background: rgb(129, 218, 167);
}

.app-bar-no-call-style {
    background: none;
}
