




















.main-error {
    margin-top: 30px;
    margin-bottom: 30px;
    .main-error-text {
        margin-left: 5px;
    }
}
