/* Keyword Search */
.pc-ns .region-search {
  text-align: center;
  padding: 0 10px;
  position: relative;
  z-index: 2;
  display: inline-block;
  margin: 0 auto 10px auto;
  max-width: 600px;
}
.pc-ns .region-search label {
  margin-bottom: 15px;
}
.pc-ns .region-search input {
  text-align: center;
  background: #f7f7f7;
  font-weight: bold;
  font-size: 18px;
  padding: 25px 15px;
  border: 1px solid #ddd;
  margin: 0 auto;
  color: #3fae29;
  border-radius: 4px;
  display: inline-block;
}
.pc-ns .region-search input#areacode {
  margin-right: 15px;
  width: calc(35% - 20px);
}
.pc-ns .region-filters {
  width: 100%;
}
.pc-ns .region-search input#city {
  margin-left: 10px;
  width: calc(65% - 20px);
}
.pc-ns .region-search input::placeholder {
  color: #aaa;
  text-transform: none;
  font-weight: 500;
}
.pc-ns .region-search input:focus {
  color: #3fae29;
}
.pc-ns .region-search form {
  width: 100%;
}
.pc-ns .region-search #cities,
.pc-ns .region-search #area_codes {
  background: #f7f7f7;
  width: calc(65% - 37px);
  position: absolute;
  right: 20px;
  border-radius: 0 0 3px 3px;
  top: 82px;
  color: #222;
  font-weight: 600;
  box-shadow: 0 4px 10px rgb(0, 0, 0, 0.2);
  padding: 0;
  max-height: 50vh;
  overflow-y: scroll;
}
.pc-ns .region-search #area_codes {
  width: calc(35% - 30px);
  right: auto;
  left: 20px;
}
.pc-ns .region-search #cities.hidden,
.pc-ns .region-search #area_codes.hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.pc-ns .region-search .city {
  display: block;
  padding: 5px;
  cursor: pointer;
  margin: 2px 0;
}
.pc-ns .region-search .city:hover {
  background-color: #eee;
}
.pc-ns .region-search .city span {
  color: #3fae29;
  margin-left: 5px;
  font-weight: 700;
}

@media (max-width: 568px) {
  .pc-ns .region-search input {
    font-size: 14px;
    padding: 25px 5px;
  }
  .pc-ns .region-search input#areacode {
    margin-right: 10px;
    width: calc(35% - 10px);
  }
  .pc-ns .region-search input#city {
    margin-left: 5px;
    width: calc(65% - 15px);
  }
}
