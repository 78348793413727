@font-face
  font-family: 'Montserrat-medium'
  src: local('Montserrat-medium'), url('./fonts/static/Montserrat-Medium.ttf')  format('truetype'),

@font-face
  font-family: 'Montserrat-semibold'
  src: local('Montserrat-semibold'), url('./fonts/static/Montserrat-SemiBold.ttf')  format('truetype'),


$body-font-family: Montserrat-medium, sans-serif


$typoOptions: display-4 display-3 display-2 display-1 headline title caption overline
$letterSpacing: v-tab v-input v-label

%font-choice
  font-family: $body-font-family, sans-serif !important
  font-weight: 500


%letter-spacing
  letter-spacing: 0px !important

@mixin md-typography
  @each $typoOption in $typoOptions
    .#{$typoOption}
      @extend %font-choice
      @extend %letter-spacing
  @each $ls in $letterSpacing
    .#{$ls}
      @extend %letter-spacing

.v-application
  @extend %font-choice
  @extend %letter-spacing
  @include md-typography