.header-bar {
	width: 100%;
	padding: 22px 30px;
	box-sizing: border-box;
	z-index: 1;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	max-height: 60px;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: center;
	align-items: center;
	/* 
	* Setting height to a value less than min-height
	* will properly center flex items in IE.
	*/
	height: 59px;
	min-height: 60px;
}

.header-bar.small-view {
	padding: 22px 10px;
}

.header-bar .options-menu-toggle-button .dropdown-toggle {
	float: right;
}

.header-bar .options-menu-toggle-button .dropdown-toggle:after {
	position: absolute;
	right: 10px;
	top: 11px;
}

.header-bar .options-menu-toggle-button .dropdown-menu {
	padding: 0;
	width: 256px;
    transform: translate(-106px, 27px) !important;
}

.header-bar .options-menu-toggle-button .dropdown-menu.loading .dropdown-extension-item {
	opacity: 0.7;
	cursor: progress;
	background: #eaeaea;
}

.header-bar .options-menu-toggle-button .dropdown-menu .dropdown-extension-item {
	padding: 7px 5px;
	font-size: 14px;
	display: flex;
}

.header-bar .options-menu-toggle-button .dropdown-menu .dropdown-extension-item .item-labels {
	margin-right: 8px;
}

.header-bar .options-menu-toggle-button .current-extension-dropdown button {
	font-size: 13px;
}

.header-bar .options-menu-toggle-button .dropdown-menu .dropdown-extension-item.active {
	color: green;
	font-weight: bold;
}

.header-bar .options-menu-toggle-button .dropdown-menu .dropdown-extension-item:not(:last-child) {
	border-bottom: 1px solid lightgray;
}

.header-bar .options-menu-toggle-button .dropdown-menu .dropdown-extension-item:not(.active):hover {
	background: #eaeaea;
}

.header-bar .options-menu-toggle-button .dropdown-menu .dropdown-extension-item.active:hover {
	cursor: default;
}

.header-bar .options-menu-toggle-button .dropdown-menu .dropdown-extension-item .item-values .dropdown-phone-number .extension-caller-id i.fa {
	color: #0079ff;
	font-size: 16px;
}
