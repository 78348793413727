











































































































.alert {
    .alert-container {
            border-radius: 6px;
            padding: 16px 16px 16px 14px;
            .alert-message {
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 19px; /* 158.333% */
            }
            .alert-icon {
                margin-right: 6px;
            }
        }
        .warning-focus {
            background-color: rgba(255, 204, 177, 0.55)
        }
    }
