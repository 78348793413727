.contact-item {
	padding: 15px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column
}

.contact-item:hover {
	cursor: pointer;
	background-color: rgba(0,0,0,.05)
}

.contact-bottom-panel, .contact-top-panel {
	width: 100%;
	display: -ms-flexbox;
	display: flex
}

.contact-bottom-panel {
	color: #484848
}

.contact-name {
	font-weight: 700;
	padding-bottom: 10px
}

.contact-number {
	max-width: 100%;
	margin-right: 8px
}

.contact-number-type {
	white-space: nowrap;
	margin-left: auto
}