.conversation-messages {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	height: 100%;
	overflow: hidden;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%
}

.messages-time-section {
	margin: 30px 0
}

.messages-sender-section {
	margin: 8px 0;
	overflow: hidden;
	width: auto;
}

.message-sender-container {
	height: 100%;
	vertical-align: bottom;
	margin-top: 15px;
}

.messages-sender-section-sender {
	margin: 2px 0 0 25px;
	font-size: 14px;
	color: #777;
}

.section-start-time {
	margin-bottom: 15px;
	font-size: 11px;
	font-weight: 500;
	font-family: sans-serif;
	color: #999
}

.pending-message {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-top: 15px;
}

.sending-status-indicator {
	margin-right: 30px;
	text-align: right;
	font-size: 11px;
	color: grey;
	line-height: .8;
	margin-bottom: 15px;
	margin-top: 5px;
}

.message-user-icon-container {
	position: relative;
	width: 40px;
  display: inline-block;
  float: left;
  height: 40px;
	top: 100%;
	margin: 40px 0px 10px 10px;
}

.message-user-icon-bg {
	float: left;
	position: absolute;
	bottom: 0;
	width: 40px;
	height: 40px;
	background-color: #eee;
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.message-user-icon {
	position: absolute;
	height: 85%;
	left: 50%;
	top: 50%;
	border-radius: 50px;
	-webkit-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
}