/* Search Results */
.pc-ns .results-wrapper {
  width: 100%;
}
.pc-ns .search-results {
  height: 42vh;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;
  background: #fff;
  border-top: 1px solid #eee
}

.pc-ns .search-results.short-results {
  /* height: 29vh; */
}

@media only screen and (min-height: 1000px) {
  .pc-ns .search-results {
    height: 55vh;
  }
}

.pc-ns .search-results .search-results-list.row {
  list-style: none;
  margin: 0;
  width: 100%;
  border-radius: 5px;
  justify-content: space-between;
}

.search-results-list-blur {
  opacity: 50%;
  overflow-y: hidden !important;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.pc-ns .no-results,
.pc-ns .error {
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
  top: 0;
  bottom: 0;
  position: absolute;
  text-align: center;
  padding-top: 15vh;
}
.pc-ns .no-results h3,
.pc-ns .error h3 {
  font-size: 21px;
  line-height: 1.5;
  /* font-style: italic; */
  font-weight: bold;
}
.pc-ns .no-results h3 a,
.pc-ns .error h3 a {
  color: #3fae29;
  text-decoration: none;
}
.pc-ns .no-results .results-message
{
  color: #66737A;
  width: 60%;
  margin: auto;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05000000074505806px;
  text-align: center;
}

.no-results-header {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
}

.search-error-page {
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
  top: 0;
  bottom: 0;
  position: absolute;
  text-align: center;
  padding-top: 10vh;
}

.search-error-character {
  display: inline-block;
}

@media (max-width: 490px) {
  .search-error-character {
    display: none;
  }
}

.search-error-message-wrapper {
  display: inline-block;
  max-width: 30%;
  vertical-align: middle;
}

.search-error-header {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: black;
}

.search-error-message {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.05000000074505806px;
  text-align: center;
  color: #364047;
  margin: 2px 0px;
}

.search-error-action {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: #3FAE29 !important;
}
