/* Selected Numbers */
.pc-ns .numbers {
  position: relative;
  padding: 10px 0 20px 0;
  width: 100%;
  border-top: 1px solid #eee;
  max-height: 100px;
}
/* .pc-ns .numbers:before {
  position: absolute;
  width: 100%;
  content: "";
  height: 0px;
  top: 0;
  bottom: 0;
  display: block;
  background: #fff;
  box-shadow: 0px 0 10px rgba(155, 113, 113, 0.09);
  border-bottom: 3px solid #eee;
  z-index: 1;
} */
.pc-ns .numbers-list {
  padding: 0;
  list-style: none;
  margin: 0 auto;
  width: 100%;
  max-height: 100px;
  overflow: scroll;
}
.pc-ns .numbers li {
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 7px 15px 7px 15px;
  /* margin-right: 10px; */
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  /* font-family: roboto-mono, monospace; */
  background: #48B9D5;
  width: 218px;
}
.pc-ns .numbers li .alpha {
  color: white;
  /* font-family: roboto-mono, monospace; */
}
.pc-ns .numbers li .price {
  margin-left: auto;
  font-size: 14px;
  color: white;
  font-weight: 400;
  font-family: NTR;
}

.pc-ns .numbers li .price-free {
  font-size: 14px;
  color: white;
  font-weight: 400;
  /* font-family: Proxima-Nova; */
  margin-left: auto;
  font-family: NTR;
}

.pc-ns .numbers li .remove-number {
  /* background-color: #333; */
  font-weight: 600;
  color: #fff !important;
  cursor: pointer;
  margin-left: 10px;
}
/* .pc-ns .numbers li .remove-number:hover {
  background: #3fae29;
  color: #fff;
} */
.pc-ns .reserved-number {
  font-weight: 500;
  position: relative;
  color: white;
  font-family: Montserrat;
}

.search-result {
  padding: 0;
  padding-right: 10px;
}


@media only screen and (max-height: 768px) {
  .numbers-list {
    padding-bottom: 40px !important;
  }
}

@media only screen and (max-width: 500px) {
  .pc-ns .numbers {
    display: none;
  }
  .numbers-list {
    display: none;
  }

}