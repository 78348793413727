





































































.delete-default-contact-model-content {
    max-width: 298px;
    margin-right: auto;
    margin-left: auto;
    .delete-default-contact-select {
        margin-top: 10px;
    }
}
