
























































































$carret-height: 20px;
.onvego-greeting-helper-container {
    position: relative;
    background: #E6F7E3;
    filter:
        drop-shadow(0px 2px 0px #9ECE95)
        drop-shadow(0px -1px 0px #9ECE95)
        drop-shadow(1px 0px 0px #9ECE95)
        drop-shadow(-1px 0px 0px #9ECE95);
    margin-top: calc($carret-height + 11px);
    border-radius: 10px;
    padding: 20px;
}
.onvego-greeting-helper-container:after, .onvego-greeting-helper-container:before {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.onvego-greeting-helper-container:after {
    border-bottom-color: #E6F7E3;
    border-width: $carret-height;
    margin-left: -$carret-height;
}
.onvego-greeting-helper-container:before {
    border-width: 36px;
    margin-left: -36px;
}
.iab-response {
    margin-top: 18px;
    .iab-response-text {
        margin-left: 6px;
    }
}
