/* Search Result */
.pc-ns .search-results .search-result div.number-link {
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, monospace;
  font-weight: 500;
  font-size: 17px;
  position: relative;
  border-radius: 10px;
  color: #2A9BB7;
  text-decoration: none;
  width: 100%;
  z-index: 0;
  border: 1px solid #7ACCE1;
  border-bottom: 2px solid #7ACCE1;
  cursor: pointer;
  white-space: nowrap;
  box-shadow: 2px 2px 6px 0px #DEE1E3;
  box-shadow: 0px 1px 2px 0px #DEE1E3 10%;
}

div.number-link-free {
  color: #85929A !important;
  border: solid 1px #85929A !important;
  box-shadow: 0px 2px 0px #85929A !important;

  box-shadow: 2px 2px 6px 0px #DEE1E3;
  box-shadow: 0px 1px 2px 0px #DEE1E3 10%;
}

.pc-ns .search-result.vanity:hover{
  padding-bottom: 0px;
}

div.number-link-free:hover {
  background: #E9EBEC !important;
}

.pc-ns .search-results .search-result div.number-link div {
  font-family: Montserrat, monospace;
  font-weight: 600;
  font-size: 17px;
}

.pc-ns .search-result div.number-link-free:hover .e164number{
  display: inline;
}

.pc-ns .search-result div.number-link:hover {
  background: #DEF2F7;
}

.pc-ns .search-result div.number-link:hover .e164number{
  display: inline;
}

.pc-ns .search-result .vanity:hover{
  padding-bottom: 10px !important;
}

.pc-ns .search-result div.number-link span {
  color: black;
  font-weight: bold;
  font-family: Montserrat, monospace;
}
.pc-ns .search-results .search-result {
  width: 225px;
  padding: 7px;
  padding-right: 8px;
}
.pc-ns .search-results .search-result .number-price {
  font-size: 10px;
  font-weight: bold;
  font-family: Montserrat;
  position: absolute;
  right: -1px;
  top: -1px;
  padding: 5px 7px 5px 10px;
  color: #2A9BB7;
  background: #DEF2F7;
  border-radius: 0 5px 0 20px;
  border-top: solid 1px #7ACCE1;
  border-right: solid 1px #7ACCE1;
  text-decoration: none;
}

.pc-ns .search-results .search-result .number-price-free {
  font-size: 10px;
  font-weight: bold;
  font-family: Montserrat;
  position: absolute;
  right: -1px;
  top: -1px;
  padding: 5px 7px 5px 10px;
  color: #85929A;
  background: #E9EBEC;
  border-radius: 0 5px 0 20px;

  border-top: solid 1px #85929A;
  border-right: solid 1px #85929A;
  text-decoration: none;
}

.e164number {
  display: none;
  font-size: 11px !important;
  margin-top: 5px
}

@media (max-width: 576px) {
  .pc-ns .search-results .search-result div.number-link div {
    font-size: 13px;
  }
  .pc-ns .search-results .search-result .number-price {
    font-size: 8px;
  }
  .pc-ns .search-results .search-result .number-price-free {
    font-size: 8px;
  }
}

@media (max-width: 500px) {
  .pc-ns .search-results .search-result {
    width: 135px;
    padding: 7px;
  }
}