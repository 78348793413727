


































































































































































































.address-update-alert {
	display: flex !important;
	justify-content: start;
	padding: 0 10px;
	margin-bottom: 30px;
	.v-icon {
		margin-right: 6px;
	}
}
.address-radio-selector {
	min-width: 100%;
	.v-radio{
		align-items: start;
		border-radius: 10px;
		border: 1px solid var(--v-secondary-base);
		padding: 15px 15px 15px 20px;
		width: 46%;
		min-height: 110px !important;
	}
	.v-item--active {
		border-radius: 10px;
		border: 2px solid var(--v-text-primary, #364047);
	}
	.v-input--radio-group__input {
		display: flex;
		flex-direction: row !important;
		justify-content: space-between;
		width: 100%;
		.v-radio:nd-of-child(2) {
			text-align: right;
		}
	}
}
