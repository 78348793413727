.dialer-button {
    background: lightblue;
    border: none;
    box-sizing: border-box;
    box-shadow: 0 1px 5px 0px rgba(0,0,0,.3);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    font-size: 2em;
    font-weight: bold;
    color: ghostwhite;

    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.dialer-button-small {
    height: 30px;
    width: 30px;
}

.dialer-button:hover {
    background: lightskyblue;
    outline: 0;
}

.dialer-button:focus {
    outline: 0;
}

.dialer-button:focus:active {
    background-image: none;
    outline: 0;
    box-shadow: 0 2px 2px -1px rgba(0,0,0,.3);
  }