/* Loader */
.pc-ns .loader {
  width: 100%;
  position: absolute;
  height: 100%;
  background: #fff;
  z-index: 3;
}
.pc-ns .phonering-alo-phone {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
}
.pc-ns .phonering-alo-phone.phonering-alo-static {
  opacity: 0.6;
}

.pc-ns .phonering-alo-ph-circle {
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  position: absolute;
  background-color: transparent;
  border-radius: 100% !important;
  border: 2px solid rgba(30, 30, 30, 0.4);
  border: 2px solid #3fae29 9;
  opacity: 0.1;
  -webkit-animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
  animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.pc-ns .phonering-alo-phone.phonering-alo-active .phonering-alo-ph-circle {
  -webkit-animation: phonering-alo-circle-anim 1.1s infinite ease-in-out !important;
  animation: phonering-alo-circle-anim 1.1s infinite ease-in-out !important;
}

.pc-ns .phonering-alo-phone.phonering-alo-static .phonering-alo-ph-circle {
  -webkit-animation: phonering-alo-circle-anim 2.2s infinite ease-in-out !important;
  animation: phonering-alo-circle-anim 2.2s infinite ease-in-out !important;
}

.pc-ns .phonering-alo-phone.phonering-alo-hover .phonering-alo-ph-circle,
.pc-ns .phonering-alo-phone:hover .phonering-alo-ph-circle {
  border-color: #3fae29;
  opacity: 0.5;
}

.pc-ns .phonering-alo-phone.phonering-alo-green .phonering-alo-ph-circle {
  border-color: #3fae29;
  border-color: #3fae29;
  opacity: 0.5;
}
.pc-ns .phonering-alo-phone.phonering-alo-gray .phonering-alo-ph-circle {
  border-color: #3fae29;
  opacity: 0.5;
}

.pc-ns .phonering-alo-ph-circle-fill {
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50px;
  position: absolute;
  background-color: #000;
  border-radius: 100% !important;
  border: 2px solid transparent;
  -webkit-animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.pc-ns .phonering-alo-phone.phonering-alo-active .phonering-alo-ph-circle-fill {
  -webkit-animation: phonering-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
  animation: phonering-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
}

.pc-ns .phonering-alo-phone.phonering-alo-static .phonering-alo-ph-circle-fill {
  -webkit-animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  opacity: 0 !important;
}

.pc-ns .phonering-alo-phone.phonering-alo-green .phonering-alo-ph-circle-fill {
  background-color: #3fae29;
  background-color: #3fae29 9;
}

.pc-ns .phonering-alo-phone.phonering-alo-gray .phonering-alo-ph-circle-fill {
  background-color: #3fae29;
  opacity: 0.75 !important;
}

.pc-ns .phonering-alo-ph-img-circle {
  width: 60px;
  height: 60px;
  top: 70px;
  left: 70px;
  position: absolute;
  /* background: rgba(30, 30, 30, 0.1)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNmlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjarY6xSsNQFEDPi6LiUCsEcXB4kygotupgxqQtRRCs1SHJ1qShSmkSXl7VfoSjWwcXd7/AyVFwUPwC/0Bx6uAQIYODCJ7p3MPlcsGo2HWnYZRhEGvVbjrS9Xw5+8QMUwDQCbPUbrUOAOIkjvjB5ysC4HnTrjsN/sZ8mCoNTIDtbpSFICpA/0KnGsQYMIN+qkHcAaY6addAPAClXu4vQCnI/Q0oKdfzQXwAZs/1fDDmADPIfQUwdXSpAWpJOlJnvVMtq5ZlSbubBJE8HmU6GmRyPw4TlSaqo6MukP8HwGK+2G46cq1qWXvr/DOu58vc3o8QgFh6LFpBOFTn3yqMnd/n4sZ4GQ5vYXpStN0ruNmAheuirVahvAX34y/Axk/96FpPYgAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAAB/ElEQVR42uya7W3CMBCG31QM4A1aNggTlG6QbpBMkHYC1AloJ4BOABuEDcgGtBOETnD9c1ERCH/lwxeaV8oPFGP86Hy+DxMREW5Bd7gRjSDSNGn4/RiAOvm8C0ZCRD5PSkQVXSr1nK/xE3mcWimA1ZV3JYBZCIO4giQANoYxMwYS6+xKY4lT5dJPreWZY+uspqSCKPYN27GJVBDXheVSQe494ksiEWTuMXcu1dld9SARxDX1OAJ4lgjy4zDnFsC076A4adEiRwAZg4hOUSpNoCsBPDGM+HqkNGynYBCuILuWj+dgWysGsNe8nwL4GsrW0m2fxZBq9rW0rNcX5MOQ9eZD8JFahcG5g/iKT671alGAYQggpYWvpEPYWrU/HDTOfeRIX0q2SL3QN4tGhZJukVobQyXYWw7WtLDKDIuM+ZSzscyCE9PCy5IttCvnZNaeiGLNHKuz8ZVh/MXTVu/1xQKmIqLEAuJ0fNo3iG5B51oSkeKnsBi/4bG9gYB/lCytU5G9DryFW+3Gm+JLwU7ehbJrwTjq4DJU8bHcVbEV9dXXqqP6uqO5e2/QZRYJpqu2IUAA4B3tXvx8hgKp05QZW6dJqrLTNkB6vrRURLRwPHqtYgkC3cLWQAcDQGGKH13FER/NATzi786+BPDNjm1dMkfjn2pGkBHkf4D8DgBJDuDHx9BN+gAAAABJRU5ErkJggg==)
    no-repeat center center; */
  background: transparent
    url("https://phone.com/wp-content/plugins/phonecom-number-search/img/phonecom-icon.png")
    no-repeat center center;
  border-radius: 100% !important;
  border: 2px solid transparent;
  -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-size: 100%;
}

.pc-ns .phonering-alo-phone.phonering-alo-active .phonering-alo-ph-img-circle {
  -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out !important;
  animation: phonering-alo-circle-img-anim 1s infinite ease-in-out !important;
}

.pc-ns .phonering-alo-phone.phonering-alo-static .phonering-alo-ph-img-circle {
  -webkit-animation: phonering-alo-circle-img-anim 0 infinite ease-in-out !important;
  animation: phonering-alo-circle-img-anim 0 infinite ease-in-out !important;
}
.pc-ns .phonering-alo-phone.phonering-alo-green .phonering-alo-ph-img-circle {
  background-color: #3fae29;
  background-color: #3fae29 9;
}
.pc-ns .phonering-alo-phone.phonering-alo-gray .phonering-alo-ph-img-circle {
  background-color: #3fae29;
}

@-webkit-keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.5) skew(1deg);
    -webkit-opacity: 0.1;
  }

  30% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    -webkit-opacity: 0.5;
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    -webkit-opacity: 0.1;
  }
}

@-webkit-keyframes phonering-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }

  100% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.5) skew(1deg);
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }

  30% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.5) skew(1deg);
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }

  30% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-webkit-keyframes phonering-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }

  100% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@keyframes phonering-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }

  100% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }
}
