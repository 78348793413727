































































	@import 'vue-apps-assets/styles/scss/styles.scss';
 .v-application--wrap {
      min-height: fit-content;
    }
