





























.custom-file-input {
    opacity: 1;
}
