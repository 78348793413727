
/* Price Filter */
.pc-ns .price-filter {
  text-align: left;
  margin-bottom: 0;
  width: 175px;
  min-height: 100%;
  min-width: 8em;
  border-radius: 16px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  background: white;
  border: 1px solid #48B9D5;
  border-bottom: 3px solid #48B9D5;
  margin: 0px 165px;
  width: 185px;
}

@media (max-width: 576px) {
  .pc-ns .price-filter {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0px 165px;
    width: 185px;
  }
}

.selection-radio-button {
  padding: 5px 10px !important;
}

.pc-ns .slider-label {
  top: -10px;
}

.pc-ns .rc-slider {
  margin-top: 20px;
}
.pc-ns .rc-slider-handle {
  border-color: #3fae29;
}
.pc-ns .rc-slider-handle:hover {
  border-color: #333;
  cursor: pointer;
}
.pc-ns .rc-slider-handle:active {
  box-shadow: none;
  border-color: #3fae29;
  background: #3fae29;
}
.pc-ns .rc-slider-handle:focus {
  border-color: #3fae29;
}
.pc-ns .rc-slider-track {
  background: #3fae29;
}
.pc-ns .rc-slider-tooltip-inner {
  padding: 5px 10px;
  border-radius: 3px;
  background: #333;
}
.pc-ns .rc-slider-tooltip-arrow {
  border-top-color: #333 !important;
}

.rc-tooltip, .rc-slider-tooltip {
  z-index: 100 !important;
}

.pc-ns .slider label {
  position: absolute;
  top: 0px;
  left: calc(50% - 15px);
}
.pc-ns .rc-slider-mark-text {
  font-size: 10px;
  font-style: italic;
  position: absolute;
  top: -2px;
}

.rc-slider-mark-text {
  font-size: 1.5em !important;
}
.rc-slider-mark-text-active {
  font-size: 1.5em !important;
}