





















































































































































































































































































































































































.cart-container {
	.cart-with-badge {
		position: relative;
		margin-right: 10px;
		#cart-items-number {
			position: absolute;
			right: -6px;
			top: -5px;
			background: var(--v-primary-darken1);
			border-radius: 50%;
			color: #FFF;
			height: 20px;
			min-width: 20px;
			font-weight: 600;
			font-size: 10px;
			line-height: 20px;
			letter-spacing: -0.1 px;
			text-align: center;
			z-index: 1;
		}
	}
	.infolink-devices {
		margin-top: -5px !important;
	}
	.display-available-users {
		width: 10px;
		margin-right: 3px;
	}
}
.buy-device-no-extensions-alert {
	padding: 15px;
	max-width: 600px;
	margin: 14px auto 20px auto;
	.v-icon {
		margin-right: 6px;
	}
}
.text-field {
	max-width:250px !important;
	width: 250px !important;
}
.infotip-text {
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
}
.order-product {
	.backordered-wrapper {
		position: absolute;
		top: 20px;
		right: 30px;
		z-index: 1;
		.backordered {
			text-transform: uppercase;
			text-align: right;
			padding: 4px 6px;
			border-radius: 2px;
			background: rgba(255, 204, 177, 0.40);
			width: 100%;
		}
	}
}
.device-description-modal-container {
    overflow-x: hidden !important;
    overflow-y: auto;
    direction: rtl;
	&.has-features {
		height: 365px !important;
	}
}
.item_direction {
    direction:ltr;
}
