.media-modal-content .media-gallery .media-gallery-items {
	padding: 0px 4.2em;
}

.media-modal-content .media-gallery .media-gallery-items > div {
	overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
	scrollbar-width: none;
	height: 100%;
}

.media-modal-content .media-content-wrapper {
	height: 100%;
}
.media-modal-content .media-content-wrapper .media-options {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 30px;
	font-size: 16.8px;
	display: flex;
	justify-content: space-between;
	z-index: 2;
	transition: visibility 0.5s, opacity 0.5s linear;
}

.media-modal-content .media-content-wrapper .media-options.not-visible {
	visibility: hidden;
	opacity: 0;
}

/* .media-modal-content .media-content-wrapper .media-options .media-options-left {
	display: inline-block;
}

.media-modal-content .media-content-wrapper .media-options .media-options-middle {
	display: inline-block;
}

.media-modal-content .media-content-wrapper .media-options .media-options-right {
	display: inline-block;
} */

.media-modal-content .media-content-wrapper .media-options .media-option {
	display: inline-block;
	margin-right: 10px;
	color: white;
	border-radius: 20px;
}

.media-modal-content.mobile-view .media-content-wrapper .media-options .media-option {
	background: white;
	color: black !important;
	box-shadow: 0px 0px 5px 2px grey;
}

.media-modal-content .media-content-wrapper .media-options .media-option.active {
	background: white;
	color: black;
	border-radius: 4px;
	box-shadow: 2px 2px 10px 0px grey;
	border: 1px solid gray;
}

.media-modal-content .media-content-wrapper .media-options .media-option .media-option-name {
	padding: 5px 10px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	text-align: center;
}

.media-modal-content .media-content-wrapper .media-options .media-option.active .media-option-name {
	border-bottom: 1px solid lightgray;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.media-modal-content .media-content-wrapper .media-options .media-option .media-information-item {
	display: none;
	padding: 5px 10px;
	min-width: 150px;
}

.media-modal-content .media-content-wrapper .media-options .media-option .media-information-item .media-information-label {
	font-size: 15px;
	color: gray;
	margin-right: 15px;
}

.media-modal-content .media-content-wrapper .media-options .media-option .media-information-item .media-information-value {
	font-size: 15px;
}

.media-modal-content .media-content-wrapper .media-options .media-option.active .media-information-item {
	display: flex;
	justify-content: space-between;
}

.media-modal-content .media-content-wrapper .media-options .media-option:hover {
	cursor: pointer;
}

.media-modal-content:not(.mobile-view) .media-content-wrapper .media-options .media-option:not(.active):hover {
	background: #444444;
}

.media-modal-content .media-content-wrapper .media-options .media-option.active .media-option-name:hover {
	background: #eaeaea;
}

.media-modal-content .media-content-wrapper .media-options .media-option.active:hover :not(.media-option-name) {
	cursor: auto;
}

.media-modal-content .media-content-wrapper .media-options .media-option #close-media-modal {
	font-size: 25px;
}

.media-modal-content .media-content-wrapper .navigation-sign {
	position: absolute;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	font-size: 100px;
	font-family: serif;
	color: white;
	z-index: 1;
	font-size: 1rem;
	transition: visibility 0.5s, opacity 0.5s linear;
}

.media-modal-content .media-content-wrapper .navigation-sign.not-visible {
	visibility: hidden;
	opacity: 0;
}

.media-modal-content.mobile-view .media-content-wrapper .navigation-sign {
	font-size: .5rem;
}

.media-modal-content .media-content-wrapper .navigation-sign .navigation-sign-clickable-area span {
	border-top: 4em solid transparent;
    border-bottom: 4em solid transparent;
    width: 0;
	height: 0;
}

.media-modal-content .media-content-wrapper .navigation-sign .navigation-sign-clickable-area {
	width: 10em;
    height: 12em;
    display: flex;
    justify-content: center;
	align-items: center;
	cursor: pointer;
}

.media-modal-content .media-content-wrapper .navigation-sign .navigation-sign-clickable-area:hover span {
	color: #e2e2e2;
}

.media-modal-content .media-content-wrapper .navigation-sign.previous-media {
	left: 0;
	padding-left: 10px;
}

.media-modal-content .media-content-wrapper .navigation-sign.previous-media .navigation-sign-clickable-area span {
	border-right: 4em solid;
	margin-right: 2em;
}

.media-modal-content .media-content-wrapper .navigation-sign.next-media {
	right: 0;
	padding-right: 10px;
}

.media-modal-content .media-content-wrapper .navigation-sign.next-media .navigation-sign-clickable-area span {
	border-left: 4em solid;
	margin-left: 2em;
}

.media-modal-content .media-content-wrapper .media-content {
	/* position: absolute;
	top: 50%;
    left: 50%;
	transform: translate(-50%, -50%); */
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.media-modal-content .media-content-wrapper .media-content .shown-media {
	width: 90%;
	height: 95%;
}

.media-modal-content .media-content-wrapper .media-content .shown-media > div {
	width: 100%;
	height: 100%;
	text-align: center;
	position: relative;
}

.media-modal-content .media-content-wrapper .media-content .shown-media .current-media {
	max-width: 100%;
	max-height: 100%;
	-webkit-user-drag: none; /* Prevents dragging of images/divs etc */
	user-drag: none;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.media-modal-content .media-content-wrapper .media-content .shown-media .current-media img {
	-webkit-user-drag: none; /* Prevents dragging of images/divs etc */
	user-drag: none;
}

.media-modal-content .media-content-wrapper .media-content .shown-media .current-media.loading-media {
	border-radius: 40px;
}

.media-modal-content .media-content-wrapper .media-content .shown-media .current-media.other-type-media {
	background: white;
	border-radius: 4px;
	padding: 10px 15px;
	color: black;
}

.media-modal-content .media-content-wrapper .media-content .shown-media .current-media.other-type-media .other-type-media-content-wrapper {
	display: flex;
	font-size: 1rem;
}

.media-modal-content.mobile-view .media-content-wrapper .media-content .shown-media .current-media.other-type-media .other-type-media-content-wrapper {
	font-size: 0.45rem;
}

.media-modal-content .media-content-wrapper .media-content .shown-media 
.current-media.other-type-media .other-type-media-content-wrapper .media-content-info {
	display: flex;
	flex-direction: column;
	font-size: 3em;
	justify-content: space-around;
	margin-left: 0.66em;
}

.media-modal-content .media-content-wrapper .media-content .shown-media 
.current-media.other-type-media .other-type-media-content-wrapper .media-content-info a {
	width: fit-content;
}

.media-modal-content .media-content-wrapper .media-content .shown-media .current-media.other-type-media
.other-type-media-content-wrapper .media-content-info .media-content-filename {
	max-width: 15em;
	max-height: 6em;
	word-break: break-all;
    overflow: auto;
}

.media-modal-content.mobile-view .media-content-wrapper .media-content .shown-media .current-media.other-type-media
.other-type-media-content-wrapper .media-content-info .media-content-filename {
	max-width: 11em;
}

.media-modal-content .media-gallery {
	position: relative;
	border-top: 1px solid #2f2f2f;
}

/* This is the infinite scroller */
.media-modal-content .media-gallery .media-gallery-items.smooth-scroll div {
	scroll-behavior: smooth;
}

.media-modal-content .media-gallery .media-gallery-items div::-webkit-scrollbar {
	display: none;
}

.media-modal-content .media-gallery .media-gallery-items .gallery-items-loader {
	margin: 0 5px;
    display: inline-block;
	/* align-items: center; */
	width: 3.37em;
	height: 9.7em;
	position: absolute;
    top: 10px;
}

.media-modal-content .media-gallery .media-gallery-items .gallery-items-loader.left-loader {
	left: 0px;
}

.media-modal-content .media-gallery .media-gallery-items .gallery-items-loader.right-loader {
	right: 0px;
}

.media-modal-content .media-gallery .media-gallery-items .gallery-items-loader > div {
	position: relative;
	width: 100%;
	height: 100%;
}

.media-modal-content .media-gallery .media-gallery-items .gallery-items-loader > div > img {
	border-radius: 4em;
	color: transparent;
    width: 3.37em;
    max-height: 9.7em;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.media-modal-content.mobile-view .media-gallery .media-gallery-items .gallery-item {
	/* font-size: 5px; */
}

/* .media-modal-content .media-gallery .media-gallery-items .gallery-item:not(.selected) {
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .6);
}

.media-modal-content .media-gallery .media-gallery-items .gallery-item:not(.selected):hover {
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .5);
} */

/* .media-modal-content .media-gallery .media-gallery-items .gallery-item:not(.selected) .gallery-item-extension {
	opacity: 0.4;
}

.media-modal-content .media-gallery .media-gallery-items .gallery-item:not(.selected):hover .gallery-item-extension {
	opacity: 0.5;
} */

.media-modal-content .pdf_link{
	color: #F15642;
	font-size: 180%;
	font-weight: bold;
	font-family: cursive
}
.media-modal-content .pdf_link img{
	display:block
}

#media-download {
	font-weight: bolder;
	text-align: center;
	margin: auto;
	width: 80%;
	border-radius: 10px;
	padding: 1px;
	background-color: cornflowerblue;
	cursor: pointer;
}

#media-download:hover {
	background-color: dodgerblue;
}

i.fa.fa-download {
	cursor: pointer;
}