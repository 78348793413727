




















.help-text.v-alert {
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 0;
		&.has-icon {
			padding-left: 0;
		}
		.prepend-icon {
			margin-right: 15px !important;
		}
		.v-alert__wrapper {
			align-items: start !important;
		}
	}
