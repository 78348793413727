.send-message-container:focus {
	outline: none;
}

.send-message-container .send-message-content:focus {
	outline: none;
}

.send-message-container.dropzone--isActive .send-message-content {
	filter: blur(3px);
	padding: 12px;
}

.cant-send-message-error {
	position: absolute;
	top: -16px;
	left: 15px;
	font-size: 11px;
	color: #c70000;
}

.cant-send-message-error.media-error {
	top: unset;
	bottom: -5px;
}

.send-message-media-display {
	padding: 10px 20px;
}

.send-message-thumbnail-container {
	position: relative;
	padding: 5px 5px 0 0;
	display: inline-block
}

.send-message-media-display .send-message-thumbnail-container .file-err-msg {
	background-color: red;
    position: absolute;
    top: -3px;
    left: -5px;
    border-radius: 15px;
    width: 22px;
    height: 22px;
    text-align: center;
    color: #fff;
    z-index: 1;
    border: 2px solid #efeeee;
	font-weight: bold;
	cursor: help;
}

.send-message-media-display .send-message-thumbnail-container.file-too-big .media-container img.thumbnail {
	border-color: red !important;
}

.send-message-media-display .send-message-thumbnail-container.file-too-big:not(:first-child) {
	margin-left: 5px;
}