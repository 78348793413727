

























































.collapsable-title {
    padding: 13.5px 0;
}
.collapsable-content {
    animation-duration: 0.5s;
    animation-name: animate-opacity;
  }

  @keyframes animate-opacity {
    from {
        transform: translateY(-4%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
  }
