.pc-ns .adv-filters {
  width: 100%;
  position: relative;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding-right: 0px;
}
@media (max-width: 380px) {
  .pc-ns .adv-filters {
    padding: 0px;
  }
}
.pc-ns .position-search {
  text-align: left;
  margin: 10px auto 15px 15px;
  position: relative;
  font-size: 12px;
}
.pc-ns  .position-search-label {
  margin-left: 0.8%;
}
.pc-ns .position-search .num,
.pc-ns .position-search span {
  display: inline-block;
  width: 6.9%;
  margin: 0.8% !important;
  text-align: center;
}
.pc-ns .position-search span {
  width: 2%;
  font-weight: 100;
  font-size: 30px;
  /* color: #999; */
}
.pc-ns .position-search .num {
  padding: 0px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  /* background: #f7f7f7; */
  font-weight: bold;
  width: 32px;
  /* color: #3fae29; */
  border-radius: 4px;
  border: 1px solid #A6AFB5;
  border-bottom: 2px solid #A6AFB5;
}

.pc-ns .position-search .num:focus {
  border-color: black !important;
  border-bottom: 2px solid black !important;
}


/* @media (max-width: 568px) {
  .pc-ns .position-search .num {
    width: 8%;
    margin: 0.6% !important;
  }
} */
.position-search-dash {
  color: #A6AFB5;
}


@media (max-width: 568px) {
  .pc-ns .position-search .num {
    width: 20px;
    height: 27px;
    font-size: 11px;
  }
  .pc-ns .position-search span {
    font-size: 20px;
  }
  .pc-ns .position-search {
    text-align: left;
    margin: 10px 5px 10px 5px;
    position: relative;
    font-size: 11px;
  }
}
