.selections-made {
  /* color: #3fae29;
  font-weight: bold;
  background-color: #e3efdc !important; */
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  margin-top: 7px;
  padding: 0;
  outline: none;
  max-height: 1.5em;
  width: 100%;
  text-align: left;
}

.loading {
  cursor: progress !important
}

.button-wrapper {
  float: left;
  margin-right: 1em;
  padding-left: 10px;
  position: relative;
  width: 150px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #B0B0B0;
  border-bottom: 2px solid #B0B0B0;
  font-size: 12px;
  text-align: left;
  color: #364047;
  height: 30px;
  box-shadow: 2px 2px 6px 0px #DEE1E3;
  box-shadow: 0px 1px 2px 0px #DEE1E3 10%;
}

.button-wrapper.active {
  background: #EAF7FA;
  border: 1px solid #48B9D5;
  border-bottom: 2px solid #48B9D5;
}

.button-wrapper.open {
  background: #fff;
  border: 1px solid #48B9D5;
  border-bottom: 2px solid #48B9D5;
}
.x-icon{
  height:	12px !important;
  padding-left: 5px;
}

.filter-button-dropdown {
  float: right;
  margin-right: 6px;
}

.filter-button-label {
  width: 80%;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 380px) {
  .button-wrapper {
    width: 100px;
  }
  .filter-button-label {
    width: 70%;
  }
}

.link-button:hover,
.link-button:focus {
  text-decoration: none !important;
  /* outline: none;
  background-color: #eeeeee;
  color: #666666; */
}

#filter-button-no-focus-border:focus {
  border: none !important;
  border-width: 0px !important;
}
