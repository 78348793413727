$fontSize: 12px;
$color: var(--v-text-base);

$headerHeight: 57px; /* LoaderBar.vue */
$header-border: 1px solid rgba(133, 146, 154, 0.20);

$light-error: #FCE4E2;

$border-color: rgba(127, 127, 127, 0.26);
$border: 1px solid $border-color;
$table-border: thin solid rgba(0,0,0,0.12);

$bg: #f2f2f2;
$frame-background: #F4F5F6;
$space: 10px;

$sidebar-width: 226px; /* if you change this, change the Sidebar.vue => v-navigation-drawer width, LoaderBar.vue */
$input-height: 44px;
$tight-input-height: 40px;

$page-width: 800px;
$large-page-width: 1050px;

$border-radius: 17px;