



























.custom-select {
  padding: 0;
  border: 0;
  .v-input__icon--append {
    margin-right: 9px;
  }
}
