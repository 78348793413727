.pricing-page-wrapper {
    width: 45%;
    margin: auto;
    margin-top: 70px;
    max-width: 600px;
}

@media (max-width: 600px) {
    .pricing-page-wrapper {
        width: 90%;
        margin: auto;
        margin-top: 70px;
        max-width: 600px;
    }
}

.pricing-page-title {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 15px;
}

.pricing-page-charge {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #2A9BB7;
    margin-bottom: 10px;
}

.pricing-left-column {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
}

.pricing-right-column {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: right;
    display: flex;
}

.pricing-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    direction: ltr;
}

.pricing-page-spacer {
    color: #C7CDD1;
    margin: 0px;
    /* border-bottom: 1px solid #C7CDD1; */
}

.pricing-total-label {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
}

.pricing-total {
    font-weight: 700 !important;
}

.pricing-remove-number {
    /* position: fixed; */
    padding-left: 10px;
    font-weight: 300;
    color: black;
    height: 0px;
    margin-right: -15px;
}

.pricing-remove-number:hover {
    /* position: fixed; */
    /* padding-left: 10px; */
    font-weight: 700;
    /* color: black; */
    /* height: 0px; */
}

.pricing-remove-loader {
    height: 55vh;
    position: relative;
}