/* Keyword Search */
.pc-ns .keyword-search {
  text-align: center;
  padding: 0;
  position: relative;
  z-index: 2;
  display: inline-block;
  font-size: 12px;
  margin-top: 2px;
}
.pc-ns .keyword-search input {
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 14px;
  max-width: 500px;
  border: 1px solid #A6AFB5;
  border-bottom: 2px solid #A6AFB5;
  margin: 0 auto;
  color: black;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  text-align: left;
}

.pc-ns .keyword-search input:focus {
  border-color: black !important;
  border-bottom: 2px solid black !important;
}


.pc-ns .keyword-search input::placeholder {
  /* color: #aaa; */
  text-transform: none;
  font-weight: 500;
}

.pc-ns .keyword-search div {
  margin: 10px 0px 0;
  width: 100%;
  text-align: left;
  display: inline-block;
  position: static;
  clear: both;
  margin-bottom: 5px;
}

@media (max-width: 568px) {
  .pc-ns .keyword-search input {
    font-size: 11px;
    height: 30px;
    margin: 3px !important;

  }
  .pc-ns .keyword-search div {
    margin: 0px 5px 0;

  }
  .pc-ns .keyword-search {
    font-size: 11px;
  }
}

