.default-branding {
    #main-container {
        .list-link, .theme--light.list-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.list-link {
            color: var(--v-primary-darken4) !important;
            cursor: pointer;
        }
    }
}
.avo-branding, .avo_master-branding {
    #main-container {
        .list-link, .theme--light.list-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.list-link {
            color: var(--v-primary-darken1) !important;
            cursor: pointer;
        }
    }
}
.idseal-branding, {
    #main-container {
        .v-text-field input::placeholder, .v-select select::placeholder, .v-label:not(.select-all .v-label):not(.v-input--switch .v-label):not(.v-input--radio-group .v-label) {
            color: #8C98A0 !important;
            opacity: 1;
        }
    }
}