






































































.vertical-table {
    .vertical-table-row {
        padding: 8px 0px;
        align-items: center;
    }
    .vertical-table-title {
      border-top: 2px solid var(--v-info-base);
      &:not(:first-child) {
        margin-top: -1px;
      }
    }
    .items {
      box-shadow: inset 0px -1px 0px rgba(133, 146, 154, 0.2);
      height: 50px;
    }
    .vertical-table-divider {
      box-shadow: inset 0px -1px 0px var(--v-info-lighten1);
      height: 1px;
      width: 100%;
      margin-top: -1px;
    }
}
