/* Search Filters */
.pc-ns .search-filters {
  /* font-size: 22px; */
  position: relative;
}
.pc-ns .search-filters label {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 2;
  display: inline-block;
}
.pc-ns .search-filters:after {
  position: absolute;
  width: 100%;
  content: "";
  height: 10px;
  bottom: -10px;
  display: block;
  background: #fff;
  /* box-shadow: 0px 10px 10px rgba(155, 113, 113, 0.05); */
  /* border-bottom: 1px solid #eee; */
  z-index: 1;
}
.search-filters-filter {
  padding: 35px 5px 35px;
}

.search-filters-filter.mobile{
  padding: 15px 5px 15px;
}

.left-hand-filter {
  padding: 15px 0 12px
}

@media (max-width: 568px) {
  .left-hand-filter {
    padding: 10px 0 7px
  }
}