.btn-secondary.dropdown-toggle.delete-dropdown-toggle {
    position: absolute;
    right: 0;
    top: 13px;
    font-size: 14px !important;
    padding: 0px;
    background-color: transparent !important;
    border: none;
    color: #888 !important;
    box-shadow: none !important;
}

.btn-secondary.dropdown-toggle.delete-dropdown-toggle:active, .btn-secondary.dropdown-toggle.delete-dropdown-toggle:focus {
    background-color: transparent !important;
    outline: none;
}

.delete-dropdown-toggle > .Ripple.is-reppling {
    animation: none !important;
}