

.browser-error-container {
	/* position: absolute; */
    /* top: 0;
    bottom: 0;
    left: 0;
	right: 0; */
	margin-top: 15px;
	margin-bottom: 15px;
    display: flex;
    align-items: center;
	justify-content: center;
	text-align: center;
}
.line-wrapper {
	width: 50%;
}

.browser-error-container .line1{
	font-size: 35px;
	margin-bottom: 15px;
}

.browser-error-container .line2{
	font-size: 25px;
	margin-bottom: 15px;
}

.browser-error-container .line3{
	font-size: 25px;
}

/* override unified CSS */
.prev_button_wrapper{
	display: none !important;
}