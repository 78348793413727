







































































@import 'vue-apps-assets/styles/scss/vars.scss';
.form-label {
	min-height: $input-height !important;
	padding-left: 0px;
}
.form-input-explanation {
	 text-align: left;
	 font-size: 11px;
}
