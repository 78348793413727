.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: underline;
}

.reset-filters svg {
  position: absolute;
  height: 44px;
  width: 44px;
  color: #66737A;
  top: -8px;
}

.reset-filters-disabled svg {
  position: absolute;
  height: 36.67px;
  width: 36.67px;
  color: #66737A;
  top: -5px;
}

.reset-filters:hover svg{
  color: #48B9D5;
  cursor: pointer;
}