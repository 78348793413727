.dialer-wrapper {
    width: 100%;
    margin: auto;
}

.dialer-input {
    border: none;
    border-bottom: solid 1px lightgrey;
    background: none;
    text-align: center;
    width: 80%;
    margin: auto;
    display: block;
    font-size: 2em;
    margin-bottom: .8em;
}

.dialer-input:focus {
    outline: 0;
}

.dialer-grid {
    width: 60%;
    margin: auto;
}

.dialer-grid tr {
    width: 100%;
}

.dialer-grid td {
    width: 33.33%;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
}

.dialer-status {
    position: absolute;
    right: 0%;
    bottom: 0%;
}

.dialer-reregister {
    position: absolute;
    top: 95%;
    padding: 4px 8px;
    border-radius: 3px;
    background: rgb(129, 218, 167);
    border: none;
    color: white;
    cursor: pointer;
}