/* Actions */
.actions-row {
    text-align: center;
    background: #F4F5F6;
    border-top: 2px solid #DEE1E3;
    width: 100%;
    height: 85px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    font-family: Montserrat
}
.actions-row-mobile {
    text-align: center;
    background: #F4F5F6;
    border-top: 2px solid #DEE1E3;
    width: 100%;
    height: 85px;
    bottom: 0;
    left: 0;
    z-index: 1;
    font-family: Montserrat
}
.actions {
    /* margin: 10px 25px 10px 0px; */
    width: 100%;
    max-width: 800px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.actions-wrapper {
    margin: 0px;
    margin-left: 240px;
    width: calc(100% - 240px);
    height: 100%;
}


.actions button.btn {
    margin: 0 auto;
    display: inline-block;
    background: #3fae29;
    color: #fff;
    font-size: 17px;
    border-radius: 4px;
    padding: 10px 30px;
    min-width: 150px;
    font-weight: 500;
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    box-shadow: None;
    font-family: Montserrat
}

.actions .confirm-button button {
    padding: 11px 28px 11px;
}

.actions button.btn.disabled {
    background: #bbb;
    background-color: #bbb;
    border-color: #aaa;
    color: white !important;
    font-family: Montserrat
}

.actions button.btn.disabled:hover {
    background: #bbb;
    background-color: #bbb;
    border-color: #aaa;
}

.actions button.btn:hover {
    background: #369922;
}
.back-wrapper {
    display: flex;
    align-items: center;
}
.back-text {
    padding-left: 12.5px;
    font-size: 16px;
    color: #369922
}


@media only screen and (max-width: 1026px) {
    .actions-back-button  {
    padding-left: 5px;
    }
    .actions {
        padding-left: 0px;
        width: 100%;
        justify-content: center;
    }
    .actions-wrapper {
        margin-left: 0px;
        width: 100%;
        margin: auto !important;
    }
}