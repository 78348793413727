








































































































































































.order.show-page {
  table {
    tr {
      height: 25px;
      th {
        width: 15%;
        max-width: 15%;
        text-align: left !important;
      }
    }
  }
}
