











































.custom-switch {
      $thumb-px: 13px;
      $original-track-width: 44px;
      $original-track-height: 22px;
      $custom-track-width: 30px;
      $custom-track-height: 15px;
      max-width: fit-content !important;
      .v-input--switch__track {
        opacity: 1;
      }
      .v-label{
        // margin-left: $custom-track-width - $original-track-width;
        margin-left: -25px;
      }
      .v-input--switch__thumb.theme--light.primary--text {
        color: #FFF !important;
      }
      .theme--light.v-input--switch .v-input--switch__track:not(.primary--text) {
        color: var(--v-secondary-base) !important;
      }
      .v-input--switch__track {
        height: $custom-track-height !important;
        width: $custom-track-width !important;
        margin-top: 4px;
      }
      .v-input--switch__thumb {
        height: $thumb-px !important;
        width: $thumb-px !important;
      }
      .v-input--switch__thumb {
        margin-top: 4px;
        margin-left: -1px;
        top: calc(50% - $original-track-height/2);
        &.primary--text {
          margin-left: -8px;
        }
      }
  }
