.conversation-content {
	width: 100%;
	height: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	position: relative;
}

.conversation-content.mobile-view .sender-phone-number {
	width: 100px;
}

.conversation-messages-wrapper {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	height: 100%;
	overflow: hidden;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	z-index: 0
}

.conversation-header-from {
	display: flex;
    align-items: center;
}

.conversation-header-from > .dropdown > button {
	position: relative !important;
}

.dropdown-menu {
	min-width: 0px;
}

.conversation-content .configure-bridge-popup {
	position: absolute;
	top: 5px;
	left: 0;
	right: 0;
	width: 210px;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #a2dea2;
	background: #d0ffd0;
	border-radius: 4px;
	padding: 5px 10px;
	text-align: center;
	box-shadow: 0px 0px 10px 0px grey;
}