/* Guide */
.guide {
  font-size: 14px;
}

.guide-error {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #E85646
}

@media only screen and (max-width: 1024px) {
  .guide {
    font-size: 11px;
    display: contents;
  }
  .guide-default-tip {
    display: none;
  }
  .guide-error {
    font-size: 11px;
    line-height: 0px;
  }
}

.pc-ns .guide .guide-image {
  display: inline-block;
  position: relative;
  top: -11px;
}
.pc-ns .guide .guide-image img {
  border-radius: 100px;
  width: 60px;
}
.pc-ns .guide .guide-bubble {
  background: #eee;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 15px;
  position: relative;
  display: inline-block;
  width: calc(100% - 130px);
  opacity: 0;
}
.pc-ns .guide .guide-bubble:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(247, 247, 247, 0);
  border-right-color: #eee;
  border-width: 7px;
  margin-top: -7px;
}
.pc-ns .guide-bubble {
  animation: fade-in-right ease 0.5s forwards;
  text-align: left;
}
