





























































.custom-text-field {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .v-input__append-inner, .custom-text-field .v-input__prepend-inner {
    margin-top: 0px !important;
  }
}
