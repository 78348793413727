








































































.loader-container.large:not(.overlay-loader) {
    margin-top:  40vh;
}
.loader.absolute-loader {
    margin-top:  0 !important;
    position:  absolute;
}
.loader-spinner {
    color:  #8C98A0 !important;
    font-size:  30px !important;
}
.no_margins{
     margin: 0 !important;
}
.overlay-loader {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.7);
    z-index: 2;
    &.large .loader-inner {
        position: absolute;
        top: 50vh;
        left: calc(50vw + 112px);
    }
}
