.messenger-modal .intro-message-btn {
    background: #60aa40;
    color: #fff;
    padding: 10px 20px;
    margin-top: 20px;
    display: inline-block;
    border-radius: 3px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
}

.modal-content .messenger-modal-message {
    text-align: left;
}

.messenger-modal {
    text-align: center;
    font-size: 15px;
    margin-bottom: 0px;
}

.messenger-modal h3 {
    margin-top: 0px;
    margin-bottom: 25px;
    font-weight: bold;
}

.modal-content {
    padding: 0px 40px 40px 40px;
    padding-bottom: 20px;
    margin: auto;
    width: 60%;
}
.modal-content {
    -webkit-animation-name: zoom;
    animation-name: zoom;
    -webkit-animation-duration: .7s;
    animation-duration: .7s;
}

@media screen and (max-width: 768px) {
    .modal-content {
        width: 100%;
        -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }
}
.modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
}

.modal-X-button {
    text-align: right;
    font-size: 2em;
    margin-right: -25px;
}
.modal-X-button:after {
    display: inline-block;
    content: "\00d7";
    cursor: pointer;
}