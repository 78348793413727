@font-face {
  font-family: 'ProximaNova';
  src: local('MyFont'), url(../fonts/ProximaNova-Regular.otf) format('woff'),
  url('https://s3.us-west-2.amazonaws.com/preprod-my.phone.com/pull_requests/fonts/ProximaNova-Regular.otf') format('woff');
}

@font-face {
  font-family: 'NTR';
  src: local('MyFont'), url(../fonts/NTR-Regular.ttf) format('woff'),
  url('https://s3.us-west-2.amazonaws.com/preprod-my.phone.com/pull_requests/fonts/NTR-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  src: local('MyFont'), url(../fonts/Montserrat-Regular.ttf) format('woff'),
  url('https://s3.us-west-2.amazonaws.com/preprod-my.phone.com/pull_requests/fonts/Montserrat-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Montserrat-medium';
  src: local('MyFont'), url(../fonts/Montserrat-Medium.ttf) format('woff'), 
  url('https://s3.us-west-2.amazonaws.com/preprod-my.phone.com/pull_requests/fonts/Montserrat-Medium.ttf') format('woff');
}

@font-face {
  font-family: 'Montserrat-bold';
  src: local('MyFont'), url(../fonts/Montserrat-Bold.ttf) format('woff'),
  url('https://s3.us-west-2.amazonaws.com/preprod-my.phone.com/pull_requests/fonts/Montserrat-Bold.ttf') format('woff');
}

@font-face {
  font-family: 'Montserrat-semi-bold';
  src: local('MyFont'), url(../fonts/Montserrat-SemiBold.ttf) format('woff'), 
  url('https://s3.us-west-2.amazonaws.com/preprod-my.phone.com/pull_requests/fonts/Montserrat-SemiBold.ttf') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('MyFont'), url('https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('MyFont'), url('https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('MyFont'), url('https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2') format('woff2');
}