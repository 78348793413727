






































































.card-selector {
    .active_card .credit-card-wrapper {
        border: 2px solid var(--v-text-lighten1) !important;
    }
    .cc-radio:not(:last-child) {
    }
}
