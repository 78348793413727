/* Search Bar */
.pc-ns .searchbarWrap {
  /* max-width: 550px; */
  margin: auto;
}
.pc-ns .search-bar {
  position: relative;
  display: block;
  clear: both;
  margin: 0;
  padding: 0;
}
.pc-ns .search-bar .form-group {
  margin: 0;
}
.pc-ns .regionSearch.show,
.pc-ns .searchbarWrap.show {
  display: block;
  opacity: 1;
  visibility: visible;
}

.pc-ns .regionSearch.hidden,
.pc-ns .searchbarWrap.hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}



@media screen and (max-width: 840px) {
  .hidden-sm-xs {
    display: none;
  }
}