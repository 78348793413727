/* @import 'http://weloveiconfonts.com/api/?family=fontawesome'; */


.hover-box-wrapper {
  /* padding: 0px;
  border-width: thin;
  border-color: rgb(221, 221, 221);
  border-style: solid;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 5px 5px 5px lightgrey; */

}


.cities-selector {
  margin-bottom: 0;
  width: 250px;
  max-height: 332px;
  min-width: 8em;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 30px;
  padding-top: 30px;
  border-radius: 16px;
  background: white;
  border: 1px solid #48B9D5;
  border-bottom: 2px solid #48B9D5;
  margin-top: 0px;
}

@media (max-width: 576px) {
  .pc-ns .npa-selector {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 120%;
  }
}

.cities-list{
  overflow-y: scroll;
  /* max-height: 150px; */
  width: 100%;
  height: 100%;
}

.search-cities {
  font-size: 1em !important;
  width: 100% !important;
  margin: 0 !important;
  display: inline-block !important;
  border: none;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  height:2em !important;
  min-width: 6em;
}

input.search-cities:focus {
  outline: none !important;
  border: none !important;
}

.selectable-item{
  text-align: Left;
  font-size: 14px;
  font-weight: 500;
}

.selectable-item:hover {
  /* background: lightgray; */
  color:black;
  cursor: pointer;
}

.selectable-item .item-name {
  padding-left: 5px;
}
/* 
.selected-item{
  background: #3fae29;
  color:white;
} */

.input-wrapper {
  width: 100%;
  display:inline-block;
  position: relative;
  border-bottom: 1px solid #ccc;
}
.loader{
  top: -5px;
  left: -5px;
}

.city-background {
  margin-right: 0 !important;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500
}

.city-divider {
  border-top: 1px solid #48B9D5;
  margin: 0 -10px;
}

.selected-city-list {
  margin: 0;
  margin-top: 5px;
}