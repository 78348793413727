html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  -webkit-appearance:	none !important;
  width:	6px;
  height:	6px;
}

*::-webkit-scrollbar-thumb {
  background-color:	var(--black-transparent-20,rgba(0,0,0,.2));
  border-radius:		4px;
}

*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}
