









































































































































































































































































































$first-column-width: 30%;
.devices-buy-final-table {
	.shipping-payment-info {
		margin: 30px 0;
		display: flex;
		justify-content: start;
		.shipping-info{
			margin-right: 50px;
		}
	}
	.final-table-subtitle {
		color: var(--v-info-darken1);
		margin-bottom: 10px;
	}
	table {
		thead th {
			height: 34px !important;
			&:first-child {
				width: $first-column-width;
				padding-left: 0;
				width: 100%;
			}
			&:nth-child(2), &:nth-child(3) {
				width: 50px;
			}
		}
		tr td:first-child {
			padding-left: 0;
		}
		.t-row-bottom-bold td{
			border-bottom: 1px solid var(--v-secondary-lighten3) !important;
		}
		.border-bottom-0 {
			border-bottom: none !important;
		}
	}
	.final-table-info-note {
		margin-top:40px;
		font-size: 11px;
		line-height: 16px;
		letter-spacing: 0.1px;
		color: var(--v-text-lighten1);
		div {
			margin-bottom: 20px;
		}
		span {
			font-size: 10.5px;
			font-weight: 700;
			line-height: 16px;
			letter-spacing: 0.1px;
		}
	}
}
