.reservation-notification-wrapper {
    z-index: 200;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    background: transparent;
    opacity: 1;
    transition: opacity 1.5s;
}

.reservation-notification-wrapper.fade-out {
    opacity: 0;
}

.reservation-notification-message {
    text-align: center;
    width: 100%;
    margin: auto;
    top: 62%;
    position: absolute;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
}