


































.selection {
    height: 57px !important;
    max-height: 57px !important;
    width: 100%;
    //margin-top: 50px;
    display: flex;
    justify-content: space-between;
    .text-and-description {
        margin: auto 0;
    }
    .description {
        width: 100%;
        color: var(--v-text-lighten1);
    }
    .label {
        width: 110px;
        padding: 1px 6px 0px;
        min-height: 20px;
        background: rgba(140, 152, 160, 0.15);
        border-radius: 2px;
        margin: auto 0 auto 6px;
        color: var(--v-text-lighten1);
        line-height: 20px;
        text-transform: uppercase;
    }
}
