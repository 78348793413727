@import '~vuetify/src/styles/styles';
@import '~vuetify/src/styles/settings/_variables';
@import './vars.scss';
@import './figma-styles.scss';
@import './common-styles.scss';
@import './brandings.scss';

::-webkit-scrollbar {
  background: white;
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #B6BEC2;
  border-radius: 4px;
}
.row { // pdc changed style
  margin: -12px;
  margin-left: -11px;
}

#console {
  #main-container {
    padding-left: 0;
    padding-top: calc($headerHeight + 10px);
    #page-content  {
      margin-top: 40px;
      padding-bottom: 60px;
    }
  }
  #page-container{
    margin-left: $sidebar-width;
  }
}

#app {
  height: 100%;
  font-size: $fontSize;
  color: $color;
  a {
    text-decoration: none;
    color: var(--v-primary-darken1);
  }
  label {
    margin-bottom: 0;
  }
  header {
    width: 100%;
    height: $headerHeight;
    position: fixed;
    border-bottom: 1px solid rgba(133, 146, 154, 0.2);
    text-align: right;
    background-color: $frame-background;
    z-index: 9;
    padding-bottom: 5px;
  }
 
  #subaccounts-owner-page-container {
    margin-top: $headerHeight;
  }
  #md-and-down-header {
    position: fixed;
    top: 0;
  }

  #menu-component {
    .v-data-table__wrapper {
      overflow-y: unset;
      overflow-x: unset;
    }
  }

  .player-download-btn {
    margin-right: 6px;
  }

  .csr-header-warning {
    font-size: 1rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    color: #e00;
    width: 100%;
    height: $headerHeight;
    display: flex;
    padding: 10px;
    z-index: 3;
    background: #8a8a8a url(data:image/gif;base64,R0lGODlhMgAtALMAAPzu7vzy8vzq6vz4+Pz09P78/Pzs7Pz29v76+vzw8P7+/vro6AAAAAAAAAAAAAAAACH5BAAAAAAALAAAAAAyAC0AAAT/cMlJK1EIVGn2Nkrijd6hDIgikOxSHC0bnAugdDGFH0VOpgmgT5JQKA4mzVAiwKWMxoMoB+BFlwvTYAKsvrDV47BImEqEsYSZYoLlZoZ4/HyLoSsHhA8EvS4CLSsLd0sJAwQLAgEBCQZJMTZTaEqBKQiIFZE5aEBFbiyERAMKPT6cRnotNoAbAQgHgiMJATiDIWepLXB4mIF9rwEFt0O7FGI5RQoEA8JQlDEgn3/DyBhMCQTSOW1xWi2GCAMiRblYExdQ2hsm4cIw5OYVAmq1IzOfJoCeLPXxXOWDUj3bYMPfhmPnFFBRMNAfAoAmfHwxOGGGmWgsPCXgQZFCigEB2UwUiOXBi7OOE0wYGUCShZcC/VpcyOAhZqaaBWD60JKiZQwB1j4U2BJjxhYbNr8FrZDUA5BQGVPBYzKkCZ0+UtIEhVekYQkadBJ4UedhKrmlGZWtgfqhllm0Mm7IcVKHhI2VZWYIHMIHqwRWoEg9gQKQhCFEihg5YjhEiIGND39a6jVBk2ECZcJigWqIFAkBg4OyHbHKgytYP4ZNTeHVdN2UlElTm6aErL3XEhCSKMZEAWC+ui1qrQf09xBui4n6ksYbCzo/OS4MAJCc4rxGzv2i9CcAAHZzEQAAOw==) repeat;
    text-align: center;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    font-family: Montserrat, Helvetica, arial, sans-serif;
  }

  .invoice {
    .small-details-table-wrapper {
      margin-top: 20px;
      margin-bottom: 40px;
      iframe {
        margin-right: 55px;
        overflow-clip-margin: none !important;
        border-width: 0;
        border-style: none;
        border-color: none;
        border-image: none;
        overflow: hidden !important;
      }
      .small-details-table {
        width: 240px;
        .details-row {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          div:first-child {
            color: var(--v-info-darken1);
          }
        }
        div:first-child .details-row:first-child {
          margin-top: 0;
        }
        .details-table-note {
          margin-top: 5px;
          margin-bottom: 5px;
          color: var(--v-text-lighten1);
        }
      }
    }
    table thead tr {
      th:first-child {
        width: 150px;
        max-width: 150px;
        min-width: 150px;
      }
    }
    .v-data-table>.v-data-table__wrapper>table>thead>tr>th:last-child {
      text-align: right;
    }
  }
  #create-payment-method {
    .create-payment-method-form {
      // max-width: 470px;
      max-width: 490px;
      margin-right: auto;
      margin-left: auto;
      .main-title {
        margin-top: -14px;
      }
      .main-subtitle {
        margin-top: 30px;
        margin-bottom: 15px;
      }
    }
    .name-fields {
      .first-name {
        margin-right: 10px;
      }
      .last-name {
        margin-left: 10px;
      }
    }
    .stripe-field-base {
      border-bottom: 1px solid #ACB5BA !important;
      background-color: $frame-background;
      padding-left: 10px;
      padding-right: 10px;
      height: $input-height;
    }
    .stripe-field-invalid {
      background: var(--v-action-darken2);
    }
    .stripe-field input {
      line-height: $input-height;
    }
    @keyframes field-border-bottom-enter {
      0% {
        width: 0%;
        margin-right: 50%;
        margin-left: 50%;
      }
  
      100% {
        width: calc(100% + 20px);
      }
    }
    .animated-bottom-line {
      content: '';
      display: block;
      height: 2px;
      margin-top: -1px;
      background: transparent;
      animation-name: field-border-bottom-enter;
      animation-duration: .25s;
      margin-left: -10px;
      width: calc(100% + 20px);
    }
    .stripe-field-focus:after {
        @extend .animated-bottom-line;
        background: var(--v-primary-base);
    }
    .stripe-field-invalid:after {
      @extend .animated-bottom-line;
      background: var(--v-error-base);
    }
  }

  .no-background-hover::before {
      background-color: transparent !important;
  }
  .btn-icon-right {
    margin-left: 6px;
  }
  .card-selector {
    .v-item--active .credit-card-wrapper {
      border: 2px solid var(--v-text-lighten1);
    }

    .cc-radio:not(:last-child) {
      margin-bottom: 15px !important;
    }
  }
  .credit-card-wrapper {
    position: relative;
    border-radius: 10px;
    padding: 33px 20px 15px 20px;
    width: 350px;
    border: 1px solid rgba(133, 146, 154, 0.65);
    border-radius: 10px;
    
    .primary-credit-card-label {
      position: absolute;
      background: var(--v-secondary-base);
      left: 6px;
      top: 6px;
      color: white;
      border-radius: 6px 1px 10px;
      padding: 3px 18px;
    }
  
    .credit-cards-img {
      position: absolute;
      right: 0;
      margin-right: 25px;
      top: 0;
      margin-top: 15px;
    }
    .dots {
      font-size: 24px;
      margin-right: 6px;
    }

    .selected {
      border: 2px solid var(--v-secondary-darken4);
    }
  }

  .payment-methods.list-page {
    .credit-card-wrapper.primary-cc {
      border: 2px solid var(--v-text-base) !important;
    }
  }

  .submenu {
    margin-top: -28px;
    margin-bottom: 30px;
  }
  #auth-screen {
    margin-top: -$headerHeight;
  }
  #sidebar-container {
    // width: $sidebar-width;
    z-index: 10;
    .v-navigation-drawer--fixed {
      z-index: 10;
    }
  }

  .list-table table {
    border-collapse:collapse !important;
    tbody tr:last-child{
      border-bottom: $table-border !important;
    }
    thead tr {
      height: 34px;
    }
  }
  .list-button.custom-btn {
    border-radius: 4px !important;
    min-width: 70px;
    padding: 5px 8px 6px 8px;
    color: white;
  }
  .select-table-item {
    width: 1%;
    padding-right: 0;
    .v-input--selection-controls__ripple, .v-input--selection-controls__input {
      margin-right: 1px;
    }
  }
  .show-page, .create-page {
    margin-top: 10px !important;
  }
  .main-extension-selector {
    .extension-selector .selector,
    .extension-selector .selector-search-field,
    .extension-selector .api-autocomplete,
    .extension-selector-container {
      width: 300px !important;
      max-width: 300px !important;
    }
  }

  // tables
  .without-hover-effect tbody {
      tr:hover {
          background-color: transparent !important;
      }
  }
  .partially-bordered-td:before {
    content : "";
    border-left:1px solid #B6BEC2;
    margin-right:  15px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .zebra tbody tr:nth-of-type(even) {
      background-color: $border-color;
  }
  .theme--light.list-table.v-data-table>.v-data-table__wrapper>table>tbody>tr {
    &>td {
      color: var(--v-text-base) !important;
    }
    &.disabled-text>td {
      color: rgba(54, 64, 71, 0.4) !important;
    }
  }

  .v-overlay {
    z-index: 1201 !important; // because of configure
  }
  .v-dialog__content {
    z-index: 1202 !important;
  }

  .tight-table{
    &.v-data-table>.v-data-table__wrapper>table>tbody>tr>td, &.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
      padding: 0 3px !important;
    }
  }
  .selected-row {
    background-color: $frame-background !important;
  }
  
  .media-fragment-size {
    width: 550px !important;
    margin-left: 250px;
  }


  // divider
  .light-divider.v-divider {
      border-color: rgba(133, 146, 154, 0.65) !important;
  }
  .divider {
    background: rgba(133, 146, 154, 0.2);
    margin-top: 25px;
    margin-bottom: 5px;
  }

  // pages - font not applied in component
  .invoice {
      tbody tr:last-child {
          td {border-bottom: thin solid var(--v-text-base);}
      }
      tfoot {
          td {border-top: none !important};
      }
      thead th:first-child {
        padding-left: 0;
      }
      tr td:first-child {
        padding-left: 0;
      }
  }
  .lr-help-text {
    max-width: 900px;
  }
  .choose-vendor-modal {
    .choose-vendor-modal-title {
        span {
          display: block;  
          max-width: 300px;
          margin-bottom: 30px;
        }
    }
    .choose-vendor {
      display: flex;
      flex-direction: row;
      gap: 10px;
      cursor: pointer;
      border-radius: 15px 30px 30px 15px;
      border: 1px solid var(--secondary-border, rgba(133, 146, 154, 0.65));
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .vendor-details {
        padding: 15px 0px 15px 15px;
        display: flex;
        .vendor-text-details {
          margin: auto 0 auto 20px;
          .vendor-title {
            color: #000;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 127.273% */
            letter-spacing: -0.1px;
          }
        }
      }
      .go-fwd-btn {
        display: flex;
        justify-content: space-around;
        align-self: stretch;
        width: 48px !important;
        min-width: 48px !important;
        border-left: 1px solid rgba(133, 146, 154, 0.25);
        .v-icon {
          margin: auto 0;
        }
      }
    }
  }
  .live-answer {
    .live-answer-contact {
      box-shadow: 0px 1px 0px rgba(54, 64, 71, 0.2);
      .lr-contact-first-field {
        max-width: 218px !important;
        width: 218px !important;
      }
      .lr-contact-second-field {
        .destination-with-warm-transfer, .extension-selector, .destination-phone-number {
          width: 218px !important;
          max-width: 218px !important;
        }
        .voicemail-selector.extension-selector, .email-input {
          width: 318px !important;;
          max-width: 318px !important;;
        }
      }
      .lr-contact-third-field {
        max-width: 86px !important;
        overflow: visible !important;
      }
      .add-new-destination-btn {
        margin-top: 17px;
        margin-bottom: 12px;
      }
    }
  }

  .datetime-picker {
    .datetime-picker-date-container {
      padding-right: 7px !important;
    }
    .datetime-picker-time-container {
      padding-left: 7px !important;
    }
  }

  #main-container {
    .v-list-item:not(.conversations .v-list-item) {
      border-bottom: $border;
    }
    .light-border {
      border: 1px solid $border-color;
    }
  }
  .cart-modal-content {
    .cart-modal-title {
      margin-bottom: 10px;
    }
    .cart-modal-item {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(54, 64, 71, 0.2);
      .cart-modal-item-img {
        margin-right: 20px;
      }
    }
    .cart-modal-item:nth-child(2) {
      border-top: 1px solid rgba(54, 64, 71, 0.2);
    }
    .cart-modal-buttons {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
    }
    .cart-modal-prices {
      text-align: right;
      .cart-modal-remove-btn {
        margin-top: 10px;
      }
    }
    .cart-modal-total {
      margin-top: 15px;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      text-align: right;
    }
  }
  .phone-btn {
    &.phone-btn-inactive {
      box-shadow: 0px 1px #DEE1E4;
      color: var(--v-text-lighten1) !important;
    }
    &.custom-btn {
      border-radius: 5px !important;
      width:45px;
      min-width:45px;
      height:45px !important;
      max-height:45px !important;
      .v-btn__content{
        display: block;
        .caller-selection-letters {
            display: block;
            font-size: 8px !important;
            font-weight: 600 !important;
            line-height: 12px;
            letter-spacing: 0.4px !important;
        }
      }
    }
    .caller-selection-number {
      font-size: 11px !important;
    }
  }

  .live-receptionist {
    max-width: 900px;
  }

  // .rule-action-action {
  //   width: 50%;
  //   max-width: 50%;;
  // }

  // inputs
  // .form-field-container .form-input .common-input, .live-receptionist .common-input {
  //   max-width: 50%;
  // }
  .w-100 .common-input {
    max-width: 100% !important;
  }
  .v-text-field .v-input__slot::before, .v-select .v-input__slot::before {
    border-bottom: 5px solid red !important;
  }
  $input-padding: 10px;
  .common-input {
    .v-input__slot {
      background: $frame-background;
      padding-left: $input-padding;
    }
    &.error--text .v-input__slot {
      background: var(--v-action-darken2);
    }
    &.custom-text-field, &.custom-select, &.custom-autocomplete {
      &:has(.v-label--active) input{
        padding-bottom: 0px;
      }
      .v-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        margin-top: 8px;
      }
      .v-label--active {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 9.5px;
        line-height: 12px;
        letter-spacing: 0.2px;
        margin-top: 18px !important;
      }
    }
    input, textarea {
        color: var(--v-text-base) !important;
    }
    &.active-input input {
      color: var(--v-primary-base) !important;
    }
  }
  .v-input--is-disabled {
    &.v-text-field {
      textarea, input {
        color: rgba(0,0,0,.38) !important;
      }
    }

  }

  // material changes
  .v-text-field {
    padding-top: 0px;
    @extend .common-input;
  }
  .v-text-field .v-input__slot::before, .v-select .v-input__slot::before {
    border-style: none !important;
    border-bottom: 1px solid #ACB5BA !important;
  }
  .v-input--is-disabled .v-input__slot:before {
    border-image: none !important;
    border-bottom: 1px solid var(--v-action-lighten4) !important;
  }
  .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.without-hover-effect tr) {
    @extend .selected-row;
  }
  .v-input__append-outer {
    margin-top: 0;
    margin-bottom: 0;
    height: 46px;
    border-bottom: 1px solid #ACB5BA !important;
  }
  .v-text-field input::placeholder, .v-select select::placeholder, .v-label:not(.select-all .v-label):not(.v-input--switch .v-label):not(.v-input--radio-group .v-label) {
      color: #8C98A0 !important; // secondary base
      opacity: 1;
  }

  .v-input--switch, .v-input--radio-group, .v-radio, .v-input--checkbox{
    &:hover {
      :before {
        background-color: transparent;
      }
    }
     .v-label {
        color: var(--v-text-base) !important;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
     }
  }
  .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 10px;
  }
  .v-input--radio-group, .v-radio {
    .v-input--selection-controls__input {
        margin-right: 3px !important;
      }
  }

  .input-helper-btn {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }

  .v-card {
    .v-card__text {
      color: var(--v-text-base) !important;
    }
  }

  .v-subheader {
    color: var(--v-text-darken1) !important;
    font-weight: 600;
    border-bottom: 1px solid;
    border-color: rgba(133, 146, 154, 0.65) !important;
    &:not(:first-child) {
      border-top: 1px solid;
    }
  }

  .v-input input, .v-input__icon, .v-input__slot:not(.v-textarea .v-input__slot):not(.v-input--radio-group .v-input__slot) {
    max-height: $input-height;
    height: $input-height;
  }
  .v-select--is-multi .v-input__slot{
    max-height: none !important;
    min-height: $input-height !important;
    height: 44px !important;
    .v-list-item .v-list-item__action {
      margin-right: 66px !important;
      color: red !important;
    }
  }
  .v-select--is-multi.v-input--is-dirty {
    // &::placeholder {
    //   color: red !important;
    // }
    .v-input__slot{
      height: auto !important;
    }
  }
  .v-input--radio-group__input {
    min-height: $input-height;
  }

  .v-textarea .v-input__control {
    min-height: $input-height;
  }
  .dynamic-filters {
    margin-top: 40px;
    margin-bottom: 15px;
    .custom-text-field {
      &:hover,  &.v-input--is-focused {
        .v-input__slot {
          background-color: rgba(206, 239, 199, 0.5) !important;
        }
      }

      input, .v-input__slot:not(.v-textarea .v-input__slot):not(.v-input--radio-group .v-input__slot) {
        max-height: 38px;
        height: 38px;
        font-size: 11px;
        line-height: 16px;
        color: var(--v-primary-base);
        .v-input__append-inner {
          display: none;
        }
      }
      .v-input__prepend-inner {
        margin-top: 0;
      }
      .v-input__icon {
        height: 38px;
        max-height: 38px;
        svg {
          color: #8C98A0 !important; // secondary-base
          height: 22px;
          width: 22px;
        }
      }
    
      .v-textarea .v-input__control {
        min-height: 38px;
      }
    }
    .v-input {
     max-width: 330px !important;
     height: 38px;
   }
  }
  .small-input {
    .v-input input, .v-input__icon, .v-input__slot:not(.v-textarea .v-input__slot):not(.v-input--radio-group .v-input__slot) {
      max-height: 34px !important;
      height: 34px !important;
    }
  }
  .tight-input {
    .v-input input, .v-input__icon, .v-input__slot:not(.v-textarea .v-input__slot):not(.v-input--radio-group .v-input__slot) {
      max-height: $tight-input-height;
      height: $tight-input-height;
    }
    .v-input--radio-group__input {
      min-height: $tight-input-height;
    }

    .v-textarea .v-input__control {
      min-height: $tight-input-height;
    }
  }
  .v-data-table .v-input input, .v-data-table .v-input__icon {
    max-height: 24px;
  }

  .v-select {
    @extend .common-input;
  }

  .caption {
    font-weight: 500;
    font-size: 11px !important;
    line-height: 16px !important;
  }

  .v-label {
    font-size: $fontSize;
  }

  .v-stepper__header {
    -webkit-box-shadow: none !important;
    box-shadow:  none !important;
    min-width: 375px !important;
  }
  .v-sheet.v-stepper:not(.v-sheet--outlined) {
    box-shadow:  none !important;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }

  .table-header, .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
		box-shadow: inset 0px 1px 0px #6AB9CD, inset 0px -1px 0px rgba(192, 225, 234, 0.75);
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: var(--v-info-darken1) !important;
		height: 34px !important;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 0;
  }

  .form-input-label {
    font-size: 12px !important;
    padding-left: 1px !important;
  }

  .v-picker--date {
    .v-btn:not(.v-btn--active):hover {
      color: var(--v-primary-base) !important;
    }
    .theme--light.v-btn:hover:not(.v-btn--active):before {
      opacity: 1;
    }
    .v-btn::before {
      color:var(--v-action-lighten3) !important;
    }
    .v-btn {
      letter-spacing: 0px;
    }
    .v-date-picker-header .v-btn--round .v-icon__svg {
      color: var(--v-primary-base);
    }
  }
  .address-form {
    .custom-text-field, .custom-autocomplete {
      margin-top: 20px;
    }
    .bottom-inputs {
      .city-input{
        max-width: 180px !important;
      }
      .state-input {
        max-width: 145px !important;
      }
      .postal-code-input {
        max-width: 125px !important;
        width: 125px !important;
      }
    }
  }
  .buy-device {
    .step-1 {
      .container {
        padding: 0 10px;
      } 
      #type-filters {
        margin-bottom: 30px;
      }
      #phone-type-select {
        .v-input__slot {
          padding-right: 0 !important;
          background-color: white !important;
        }
      }
      .products-container {
        display: flex;
        flex-wrap: wrap;
        .order-product {
          background: #FFFFFF;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
          padding: 20px 30px 30px 30px;
          border-radius: 10px;
          // height: 386px; // accessoris with 2 lines titles destroys the ui
	        height: 400px;
          max-width: 322px;
          margin: 16px 10px;
          position: relative;
          .product-description {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
            margin-top: 10px;
            height: 50px;
          }
          .product-details-btn {
            padding: 0;
            margin-top: 16px;
            height: 12px;
            .v-btn__content {
              text-align: left;
              justify-content: left !important;
            }
          }
          .bottom-controls {
            position: absolute;
            bottom: 30px;
            left: 24px;
            .custom-btn.v-btn--disabled .v-icon{
              color: rgba(54, 64, 71, 0.4) !important;
            }
            .devices-quanity-input {
              border-radius: 17px;
              width: 47px !important;
              max-width: 47px !important;
              border: 1px solid rgba(133, 146, 154, 0.25) !important;
              input, .v-input__slot {
                width: 44px !important;
                max-width: 44px !important;
  
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: var(--v-text-base) !important;
              }
              .v-input__slot {
                background: white;
                padding-left: 0;
              }
              .v-input__control .v-input__slot:after {
                display: none;
              }
              &.v-input--is-disabled .v-input__slot:before {
                border-bottom: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 6px;
  }
}
.tooltip {
  z-index: 1203;
}
hr {
  margin-bottom: 0px;
  &:not(.v-stepper__header hr) {
    margin-top: 0px;
  }
}
.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
    padding: 10px !important;
    padding-top: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 10px !important;
}

//custom fields
.v-application {
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td {
    font-size: $fontSize;
      .v-input {
        margin-top: 6px;
      margin-bottom: 6px;
      .v-input__slot {
        height: 38px !important;
      }
      }
  }
  .v-tab {
    font-size: 13px;
  }
  .v-input, .v-list-item__title, .custom-btn.v-size--default, .custom-btn.v-size--large {
    font-size: $fontSize;
  }

  .v-time-picker-title {
    justify-content: center !important;
  }

  .route-configuration {
    .route-add-new-rule, .routes-add-new-action {
      border-top: 1px solid var(--v-secondary-lighten1);
      padding-top: 16px;
    }
    .route-add-new-rule {
      margin-top: 16px;
    }
    .routes-add-new-action {
      margin-top: 20px;
      margin-bottom: -24px;
    }
  }
  .buttons-vertical-divider {
    border-left: 1px solid rgba(133, 146, 154, 0.65);
  }

  .cancel-account-note {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    .v-icon {
      margin-right: 6px;
    }
  }

  .billing-administrative-contact {
    max-width: 470px;
  }

  .common-progress {
    .progress-title {
      margin-top: 30px;
    }
    .progress-percents {
      margin-top: 30px;
    }

    .bar-wrapper {
      margin-top: 15px;
      height: 6px;
      border-radius: 3px;
      max-width: 300px;
      background: rgba(133, 146, 154, 0.25);
      .bar {
        background: var(--v-primary-darken1);
        height: 6px;
        border-radius: 3px;
        transition:width 0.5s ease-in-out;
      }
    }
    .progress-message {
      margin-top: 20px;
    }
    .progress-cancel-btn {
      margin-top: 50px;
    }

    .progress-results {
      margin-top: 20px;
      .progress-results-wrapper {
        margin-top: 20px;
      }
      .progress-successful-results, .progress-error-results {
        width: 50%;
      }
      .progress-close-btn {
        margin-top: 50px;
      }
    }
  }
  .menu-options-list {
    padding: 6px 0px;
    background: #FFFFFF;
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 6px 0;
    margin-top: 20px;
    margin-left: -25px;
    &.console-menu-options-list {
      margin-top: 40px;
      margin-left: -5px;
    }
    .menu-list-item {
      padding: 10px 12px;
      cursor: pointer;
      background: #FFF;
      min-width: 180px !important;
      &:hover {
        background: var(--v-action-lighten3);
      }
    }
  }
  .more-options-button {
    background: rgba(140, 152, 160, 0.15);
    &:hover {
      background: rgba(133, 146, 154, 0.25);
    }
  }
  .selector-wrapper {
    .selector-helper-btn:hover {
      &:before {opacity: 1; background: rgba(133, 146, 154, 0.25)};
    }
  }

  .v-dialog{
     border-radius: $border-radius !important;
     overflow-x: hidden;
     &::-webkit-scrollbar {
        width: 20px;
        background: white;
        border-radius: 0 $border-radius * 2 $border-radius * 2  0;
      }
      &::-webkit-scrollbar-track {
        margin: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border: 6px solid white;
        border-radius: 9999px;
      }
     .default-modal-content {
      padding: 40px 30px;
      background: white;
      position: relative;
      .close-default-modal-x {
        position: absolute;
        float: right;
        top: 20px;
        right: 30px;
        z-index: 1;
      }
      .default-modal-title {
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.1px;
        margin-bottom: 15px;
      }
      .default-modal-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .custom-btn:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

}
.page-back-btn {
  margin-top: -26px;
  padding-left: 0 !important;
  min-width: unset !important;
  width: unset !important;
  &.page-back-btn-absolute {
    margin-top: 0;
    position: absolute;
    left: calc($sidebar-width + 40px);
    top: 25px;
  }
}
@media only screen and (min-width: #{map-get($grid-breakpoints, 'md')}) {
  .large-page-content {
    max-width: $large-page-width !important;
  }
  .visualizer-content {
    max-width: 100% !important;
    .submenu {
      max-width: $page-width !important;
    }
  }
  #page-content:not(.large-page-content, .visualizer-content), .page-content:not(.large-page-content, .visualizer-content) {
    max-width: $page-width !important;
    // margin-top: $headerHeight;
  }
  #sidebar-container {
    width: $sidebar-width;
    #main-navigation {
      top: 57px !important;
      max-height: calc(100% - 57px);
    }
    .navigation-container {
      height: calc(100% - 57px);
    }
  }
}
@media only screen and (min-width: #{map-get($grid-breakpoints, 'md')}) {
  .v-input:not(.w-100, .v-input--switch){
    // width: 350px !important;
    max-width: 350px !important;
  }
}
@media only screen and (max-width: #{map-get($grid-breakpoints, 'md')}) {
  #app {
    .address-form {
      .bottom-inputs {
        .city-input{
          max-width: 100% !important;
          width: 100% !important;
        }
        .state-input {
          max-width: 100% !important;
          width: 100% !important;
        }
        .postal-code-input {
          max-width: 100% !important;
          width: 100% !important;
        }
      }
    }
  }
  #authorized-screen {
    #page-container{
      margin-left: 0 !important;
    }
    .live-answer {
      .live-answer-contact {
        .lr-contact-first-field {
          max-width: 100% !important;
          width: 100% !important;
        }
        .lr-contact-second-field {
          .destination-with-warm-transfer, .extension-selector, .destination-phone-number {
            width: 100% !important;
            max-width: 100% !important;
          }
          .voicemail-selector .extension-selector, .email-input {
            width: 100% !important;;
            max-width: 100% !important;;
          }
        }
      }
    }
  }

  #filters-buttons{
    width: 100%;
    .custom-btn, .save-filters-btn-container, .save-filters-btn-container .custom-btn {
      margin: auto !important;
      display: block;
      margin-top: $input-top-spacing !important;
      width: 100%;
    }
  }
  .form-field-container .form-input .common-input, .live-receptionist .common-input {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1375px) {
  .products-container {
    justify-content: space-evenly !important;
  }
}
@media only screen and (max-width: 1400px) {
  #page-container{
    padding-left: 50px;
    padding-right: 50px;
  }
  .page-back-btn.page-back-btn-absolute {
    position: relative;
    left: 0;
    top: 0;
  }
}
@media only screen and (max-width: 480px) {
  #page-container{
    padding-left: 10px;
    padding-right: 10px;
  }
  .live-answer {
    .shorten-width {
      width:  100%;
    }
    .delete-destination-container {
      width: 50px;
      .remove-btn {
        margin-left: 0;
        margin-top: 5px;
        float: right;
      }
    }
  }
}
.ellipsis-text {
  max-width: 188px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}