.loader-bar {
	width: 100%;
	padding: 5px 0
}

.loader-bar-loading {
	visibility: visible
}

.loader-bar-not-loading {
	visibility: hidden
}

.pdc-loader-wrapper {
	overflow: hidden;
	display: inline-block;
	margin-left: 50%;
	-webkit-transform: translate(-50%);
	-ms-transform: translate(-50%);
	transform: translate(-50%)
}

.pdc-loader-wrapper-mui {
	overflow: hidden
}
.pdc-loader-wrapper-mui > td {
	text-align: center;
}
