











































.descriptive-selector {
	.v-input__slot {
		height: 57px !important;
		max-height: 57px !important;
	}
}
