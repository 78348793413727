


























































.custom-textarea {
    textarea::placeholder {
      padding-top: 2px;
      line-height: 19px;
      color: var(--v-text-lighten1) !important;
    }
    textarea {
      padding-top: 13px;
      padding-bottom: 13px;
      line-height: 19px;
    }
    &.v-textarea .v-text-field__slot .v-label--active {
      display: none !important;
    }
  }
