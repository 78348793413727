/* The summary-modal (background) */
.summary-modal {
	display: block ; /* Hidden by default */
	position: fixed ; /* Stay in place */
	z-index: 1 ; /* Sit on top */
	padding-top: 150px ; /* Location of the box */
	left: 0 ;
	top: 0 ;
	width: 100% ; /* Full width */
	height: 100% ; /* Full height */
	overflow: auto ; /* Enable scroll if needed */
	background-color: rgb(0,0,0) ; /* Fallback color */
	background-color: rgba(0,0,0,0.4) ; /* Black w/ opacity */
	z-index: 3 ;
	cursor: auto;
  }
  
/* summary-modal Content */
.summary-modal-content {
	position: relative ;
	background-color: #fefefe ;
	margin: auto ;
	padding: 0 ;
	border: 1px solid #888 ;
	width: 50% ;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) ;
	-webkit-animation-name: animatetop ;
	-webkit-animation-duration: 0.4s ;
	animation-name: animatetop ;
	animation-duration: 0.4s ;
	border-radius: 60px ;

}


@media only screen and (max-width: 10000px) {
	.summary-modal-content {
	  width: 35%;
	}
}

@media only screen and (max-width: 1200px) {
	.summary-modal-content {
	  width: 38%;
	}
}

@media only screen and (max-width: 992px) {
	.summary-modal-content {
	  width: 65%;
	}
}
@media only screen and (max-width: 768px) {
	.summary-modal-content {
	  width: 90%;
	}
}


/* Add Animation */
@-webkit-keyframes animatetop {
	from {top:-300px; opacity:0} 
	to {top:0; opacity:1}
}

@keyframes animatetop {
	from {top:-300px; opacity:0}
	to {top:0; opacity:1}
}


.summary-modal-body {
	padding: 2px 16px;
	overflow-y: scroll;
	direction: rtl;
	margin-left:15px;
	margin-right:15px;
	max-height: 350px;
}

.summary-modal-header {
	padding: 2px 16px;
	text-align: center;
	font-size: 37px;
	font-weight: 700;
	direction: ltr;
	margin: 15px;

}

.summary-modal-footer {
	padding: 2px 16px ;
	border: 0px;
	margin: 15px;
	direction: ltr;

}
.summary-modal-footer .modal-disclaimer{
	margin: 15px 15px 15px 30px;
    font-style: italic;
}

.modal-row{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 5px 20px 0px 15px;
	direction: ltr;
}

.modal-row.taxes-and-fees{
	margin-top: 15px;
}
.modal-row.taxes-and-fees.info{
	margin-right: -0px
}

.modal-row.total-today{
	margin-top: 50px;
	font-weight: 700;
}

.modal-row.total-today span{
	margin-right: 30px;
}

.modal-row .left-column{
	font-size: 18px;
}

.modal-row .right-column{
	font-size: 18px;
}

.modal-row .left-column.tooltip{
	font-size: 18px;
	margin-right: 25px;
}


.modal-row .row-header{
	font-size: 27px;
	font-weight: 700;
	margin-bottom: 15px;
}

.modal-row .tooltip-icon{
	cursor: help;
}
/* buttons */
.modal-buttons button.btn {
	display: inline-block;
	background: #3fae29;
	color: #fff;
	font-size: 18px;
	border-radius: 12px;
	padding: 4px 15px;
	min-width: 130px;
	font-weight: 700;
	/* text-transform: uppercase; */
  }
  
  .modal-buttons{
	display: flex;
	justify-content: center;
  }

  .modal-buttons button.btn.cancel {
	background: lightgray;
	color: black;
	margin-right: 20px;
	
  }

  .modal-buttons button.btn.disabled {
	background: #3fae29;
  }
  
  .modal-buttons button.btn:hover {
	background: #333333;
	color:white;
  }
  .modal-buttons button.btn.cancel:hover {
	background: darkgray;
	color:black;
  }
  .modal-buttons button.btn.disabled:hover {
	background: #3fae29;
  }