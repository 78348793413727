.w-subtitle-1 {
  font-size: 16px !important;
  line-height: 26px;
  line-height: 26px;
}
.w-subtitle-2 {
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 18px;
}
.w-subtitle-3 {
  font-size: 11px !important;
  font-weight: 600 !important;
  line-height: 16px;
}

.w-label {
  font-weight: 600 !important;
  font-size: 9.5px;
  line-height: 12px;
  text-transform: uppercase;
  text-align: center;
}

.w-overline {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.w-caption {
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 16px;
  letter-spacing: 0.1px !important;
}

.w-btn-base {
  display: flex;
  align-items: flex-start;
  padding: 8px 0px !important;
  height: 36px;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}
.w-btn-medium {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px;
}
.w-btn-small {
  font-weight: 600 !important;
  font-size: 11px !important;
  line-height: 12px !important;
}

.w-btn-large {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.w-body-1 {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 22px;
}

.w-body-2 {
  font-weight: 500;
  font-size: 12px !important;
  line-height: 19px;
}

.w-h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.5px;
  text-align: left;
}

.w-h4 {
  font-size: 24px !important;
  line-height: 34px;
  letter-spacing: -0.2px;
}

.w-h5 {
  font-weight: 500;
  font-size: 20px !important;
  line-height: 28px;
  letter-spacing: -0.3px;
}

.w-h6 {
  font-size: 18px !important;
  line-height: 26px;
}

.w-helper-text {
  font-size: 10.5px;
  line-height: 16px;
  letter-spacing: 0.1px !important;
}

.w-divider {
  border-color: var(--v-secondary-lighten1) !important;
  opacity: 0.65;
}