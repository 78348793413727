
























































.expand-button {
    fill: var(--v-secondary-base);
    &.open {
        transform: rotate( -180deg );
        transition: transform 150ms ease;
    }
    &.closed {
        transition: transform 150ms ease;
    }
}
