
































.buy-device-no-extensions-alert {
	padding: 15px;
	max-width: 600px;
	margin: 14px auto 20px auto;
	.v-icon {
		margin-right: 6px;
	}
}
