



















@import 'vue-apps-assets/styles/scss/vars.scss';
.custom-tabs {
    .v-tab:hover:before,
    .v-tab:hover,
    .v-tab:before {
        background-color: transparent;
    }

    .v-tab:hover:before {
        border-bottom: 4px solid var(--v-primary-base);
        opacity: 0.2 !important;
        -webkit-transition: none;
        transition: none;
        margin-bottom: -1px;
    }

    .v-tabs-slider-wrapper {
        color: var(--v-info-base) !important;
        border-width: 3px;
    }

    .v-tab {
        text-transform: none !important;
        font-weight: 500;
        font-size: 14px !important;
        color: var(--v-primary-darken1) !important;

        border-bottom: $header-border;
        &:hover {
            border-bottom: 1px solid transparent;
        }
    }

    .v-tab--active {
        color: var(--v-text-base) !important;
    }
}
