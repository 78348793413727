.voicemail-control-container {
  padding-bottom: 2px;
  overflow: hidden;
  display: block;
}

.voicemail-play-button {
  float: left;
}

.voicemail-time {
  position: relative;
  top: 4px;
  margin-left: 8px;
  font-weight: normal;
  white-space: pre;
}

.voicemail-control-seekbar {  
  float: left;
  cursor: pointer;
  height: 10px;
  border: 2px solid #7f7f7f;
  border-radius: 6px;
  margin-top: 7px;
  margin-left: 3px;
  position: relative;
  display: flex;
  width: 85%;
}

.voicemail-control-seekbar span {
  background-color: #3fad29;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 0px;
}