/*@import 'https://weloveiconfonts.com/api/?family=fontawesome';*/

.npa-selector {
  margin-bottom: 0;
  width: 175px;
  max-height: 332px;
  min-width: 8em;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 30px;
  padding-top: 30px;
  border-radius: 16px;
  background: white;
  border: 1px solid #48B9D5;
  border-bottom: 3px solid #48B9D5;
  margin-top: 0px;
}

@media (max-width: 576px) {
  .pc-ns .npa-selector {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 150px;
  }
}

.npa-list{
  overflow-y: scroll;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  font-size: 30px;
}

/*.npa-list li {*/
  /*margin: 10px 0;*/
  /*font-size: large;*/
/*}*/

.search-npa {
  font-size: 14px !important;
  width: 100% !important;
  margin: 0 !important;
  display: inline-block !important;
  border: none;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  height:2em !important;
  min-width: 6em;
  font-weight: 500
}

.selectable-item{
  text-align: left;
  font-size: 14px;
  font-weight: 500
}

.selectable-item .item-name {
  padding-left: 5px;
}

.input-wrapper {
  display:inline-block;
  position: relative;
  border-bottom: 1px solid #ccc;
}

input.search-npa:focus {
  outline: none !important;
  border: none !important;
}

.npa-background {
  margin-right: 0 !important;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.npa-divider {
  border-top: 1px solid #48B9D5;
  margin: 0 -10px;
}

.selected-npa-list {
  margin: 0;
  margin-top: 5px;
}