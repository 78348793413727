









































.custom-combobox {
    .v-input__icon--append {
        margin-right: 9px;
    }
}
