













.custom-stepper {
    &.v-stepper--alt-labels {
        .v-stepper__step__step {
            margin: -3px 14px 9px 14px;
            height: 30px;
            width: 30px;
            font-size: 13px;
            line-height: 18px;
        }
        .v-stepper__label {
            text-align: center;
        }
        .v-stepper__step--active {
            .v-stepper__step__step {
               margin: -10px 50px 6px 50px;
            }
        }
    }
    .v-stepper__step--active {
        .v-stepper__step__step {
            &.primary {
                background: rgba(192, 225, 234, 0.75) !important;
                color: var(--v-info-darken4);
                font-size: 18px;
                line-height: 18px;
                font-weight: 500;
            }
            height: 44px;
            width: 44px;
        }
        .v-stepper__label {
            color: var(--v-info-darken1) !important;
        }
    }
    .v-stepper__step--complete {
        .v-icon__component, .v-icon__svg {
            height: 16px !important;
        }
        .v-stepper__label {
            color: var(--v-primary-darken1) !important;
        }
    }
    .v-stepper__step {
        padding-bottom: 30px;
        text-align: center;
        .v-stepper__label {
            font-family: 'Montserrat-semibold', sans-serif  !important;
            font-size: 11px;
            line-height: 16px;
            color: var(--v-text-lighten2);
        }
        &:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
            background: rgba(140, 152, 160, 0.15) !important;
            color: var(--v-secondary-darken1)
        }
    }
    .v-stepper__content {
        padding: 0;
    }
}
