/* Number Type Filter */
.pc-ns .number-type-selector {
  text-align: left;
  margin-bottom: 0;
  width: 175px;
  min-height: 100%;
  min-width: 8em;
  border-radius: 16px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  background: white;
  border: 1px solid #48B9D5;
  border-bottom: 3px solid #48B9D5;
  margin-top: 10px;
}

@media (max-width: 576px) {
  .pc-ns .number-type-selector {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 20px 0px;
    width: 150px;
  }
}

.selection-checkbox-button {
  padding: 5px 10px 5px 0px !important;
}

.form-check-input{
  cursor: pointer;
}

.number-type-label {
  font-size: 0.75em !important;
  font-family: inherit;
  cursor: pointer !important;

}

